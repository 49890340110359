import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import NavFooter from '../components/NavFooter';
import NavHeader from '../components/NavHeader';
import classes from './Main.module.scss';

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: "'Mazzard', sans-serif",
    },
  },
});

function Main({ children, ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <div className="">
        <NavHeader {...props} />
        <div className={classes.main}>
          {children}
        </div>
        <NavFooter />
      </div>
    </ThemeProvider>
  );
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Main;
