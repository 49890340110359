import React from 'react';
import PropTypes from 'prop-types';
import classes from './index.module.scss';

function Badge({
  variant, filled, border, className, selected, ...props
}) {
  const name = `${variant}${filled ? '_filled' : ''}${border ? '_border' : ''}`;
  return <div className={`${classes.badge} ${classes[name]} ${selected ? classes[`${name}__selected`] : ''} ${className ?? ''}`} {...props} />;
}

Badge.defaultProps = {
  variant: 'green',
  filled: false,
  border: false,
  className: '',
  selected: false,
};

Badge.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'green',
    'red',
    'purple',
    'blue',
    'black',
    'white',
  ]),
  filled: PropTypes.bool,
  border: PropTypes.bool,
  selected: PropTypes.bool,
};

export default Badge;
