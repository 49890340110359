/* eslint-disable max-len */
import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import './index.css';
import ReactFactory from './reactFactory';
import * as serviceWorker from './serviceWorker';
import Donation from './V2/pages/Donation';

const FourOhFour = lazy(() => import('./V2/pages/404'));

const PrizeDetail = lazy(() => import('./V2/pages/PrizeDetail'));
// Subscription Portal
const Recharge = lazy(() => import('./V2/pages/Recharge'));

// Home
const Home = lazy(() => import('./V2/pages/Home'));

// Our Team
const Team = lazy(() => import('./V2/pages/Team'));

const TermsOfUse = lazy(() => import('./V2/pages/Terms'));

// Charities
const Charity = lazy(() => import('./V2/pages/Charity'));

const Challenge = lazy(() => import('./V2/pages/Challenge'));

// Contest detail
const ContestDetail = lazy(() => import('./V2/pages/SweepDetail'));
const AuctionDetail = lazy(() => import('./V2/pages/AuctionDetail'));
const NFTDetail = lazy(() => import('./V2/pages/NFTDetail'));

// PROFILES
const HeadlinerProfile = lazy(() => import('./V2/pages/HeadlinerProfile'));
const PartnerProfile = lazy(() => import('./V2/pages/PartnerProfile'));
const CustomerProfile = lazy(() => import('./V2/pages/CustomerProfile'));
const NonprofitProfile = lazy(() => import('./V2/pages/NonprofitProfile'));

const HeadlinersList = lazy(() => import('./V2/pages/HeadlinersList'));

// Blogs
const BlogDetail = lazy(() => import('./V2/pages/BlogDetail'));
const BlogList = lazy(() => import('./V2/pages/BlogList'));

// Cart
const Cart = lazy(() => import('./V2/pages/Cart'));

// Signup
const Signup = lazy(() => import('./V2/pages/SignUp'));

// Login
const Login = lazy(() => import('./V2/pages/Login'));

// How It Works
const HowItWorksPage = lazy(() => import('./V2/pages/HowItWorks'));

// About
const About = lazy(() => import('./V2/pages/About'));

// Privacy Policy
const PrivacyPolicy = lazy(() => import('./V2/pages/PrivacyPolicy'));
const SpanishPrivacyPolicy = lazy(() => import('./V2/pages/PrivacyPolicy/SpanishPrivacyPolicy'));
const CanadaPrivacyPolicy = lazy(() => import('./V2/pages/PrivacyPolicy/CanadaPrivacyPolicy'));
const CanadaFrenchPrivacyPolicy = lazy(() => import('./V2/pages/PrivacyPolicy/CanadaFrenchPrivacyPolicy'));
const UKPrivacyPolicy = lazy(() => import('./V2/pages/PrivacyPolicy/UKPrivacyPolicy'));

// TermsOfUse
// const TermsOfUse = lazy(() => import('./components/TermsOfUse'));

// OfficialRules
const OfficialRules = lazy(() => import('./V2/pages/Rules'));
const CanadaRules = lazy(() => import('./V2/pages/Rules/CanadaRules'));
const CanadaFrenchRules = lazy(() => import('./V2/pages/Rules/CanadaFrenchRules'));
const SpanishRules = lazy(() => import('./V2/pages/Rules/SpanishRules'));
const UKRules = lazy(() => import('./V2/pages/Rules/UKRules'));
const MxWweLivMorganRules = lazy(() => import('./V2/pages/Rules/MxWweLivMorganRules'));
const MxPearljamRules = lazy(() => import('./V2/pages/Rules/MxPearlJamRules'));
const MxSonicTempleCoreyTaylorRules = lazy(() => import('./V2/pages/Rules/MxSonicTempleCoreyTaylorRules'));
const MxGreenDayRules = lazy(() => import('./V2/pages/Rules/MxGreenDayRules'));
const GretaVanFleetLondon24mxRules = lazy(() => import('./V2/pages/Rules/GretaVanFleetLondon24mxRules'));
const MxCageTheElephantMsg2024 = lazy(() => import('./V2/pages/Rules/MxCageTheElephantMsg2024Rules'));
const MxPapaRoachBbq2024 = lazy(() => import('./V2/pages/Rules/MxPapaRoachBbq2024'));
const MxKillersLondon2024 = lazy(() => import('./V2/pages/Rules/MxKillersLondon2024'));
const MxMetallicaGuitar24 = lazy(() => import('./V2/pages/Rules/MxMetallicaGuitar24'));
const MxXGamesVentura = lazy(() => import('./V2/pages/Rules/MxXGamesVentura'));
const BlinkCitiFieldRules = lazy(() => import('./V2/pages/Rules/BlinkCitiFieldRules'));
const RingoStarBdayRules = lazy(() => import('./V2/pages/Rules/RingoStarBdayRules'));
const MotelyCrueFlorida = lazy(() => import('./V2/pages/Rules/MotelyCrueFlorida'));
const MetallicaFortniteRules = lazy(() => import('./V2/pages/Rules/MetallicaFortniteRules'));
const MexicoSimplePlanRules = lazy(() => import('./V2/pages/Rules/MexicoSimplePlanRules'));
const MexicoMetallica = lazy(() => import('./V2/pages/Rules/MexicoMetallica'));
const OhanaFestivalRules = lazy(() => import('./V2/pages/Rules/OhanaFestivalRules'));
const MetallicaHelpingHandsRules = lazy(() => import('./V2/pages/Rules/MetallicaHelpingHandsRules'));
const LambOFGodRules = lazy(() => import('./V2/pages/Rules/LambOFGodRules'));
const KornRules = lazy(() => import('./V2/pages/Rules/KornRules'));
const PTVRules = lazy(() => import('./V2/pages/Rules/PTVRules'));
const UshderMiamiRules = lazy(() => import('./V2/pages/Rules/UshderMiamiRules'));


// CookiePolicy
const CookiesPolicy = lazy(() => import('./V2/pages/CookiesPolicy'));


// FAQ
const Faq = lazy(() => import('./V2/pages/Faq'));

// Merch Product
const MerchProduct = lazy(() => import('./components/MerchProduct'));

// ActivateAccount
const ActivateAccount = lazy(() => import('./components/ActivateAccount'));

// Enter Without Donating
const EnterWithoutDonating = lazy(() => import('./V2/pages/EntryForm'));
const GrandPrizeEntryForm = lazy(() => import('./V2/pages/GrandPrizeEntryForm'));

// Order analytics
const OrderAnalytics = lazy(() => import('./components/OrderAnalytics'));

// Tips Merch
// const TipsMerch = lazy(() => import('./components/TipsMerch/TipsMerch'));

// Experiences
const Marketplace = lazy(() => import('./V2/pages/Experiences'));

// Experiences Category
const ExperiencesCategory = lazy(() => import('./V2/pages/ExperiencesCategory'));

// Reset Password
const ResetPassword = lazy(() => import('./components/ResetPassword'));

// Past winners
const PastWinners = lazy(() => import('./V2/pages/PastWinners'));
const MerchProductPage = lazy(() => import('./V2/pages/Merch'));

// Subscription landing page
const SubscriptionLanding = lazy(() => import('./V2/pages/SubscriptionLanding'));

// Cause page
const Cause = lazy(() => import('./V2/pages/Cause'));

// Opt out california
const OptOutCalifornia = lazy(() => import('./V2/pages/OptOutCalifornia'));

const renderReact = () => {
  const factory = new ReactFactory();


  factory.addComponent((props) => (
    <Switch>
      <Route path="/products/*"><AuctionDetail {...props} /></Route>
      <Route path="/sweeps/*"><ContestDetail {...props} /></Route>
      <Route path="/donations/*"><Donation {...props} /></Route>
      <Route path="/nfts/*"><NFTDetail {...props} /></Route>
      <Route path="/club/*"><Cause {...props} /></Route>
      <Route path="/prizes/*"><PrizeDetail {...props} /></Route>
      {/* <Route path="/merch/*"><MerchProduct {...props} /></Route> */}
      <Route path="/merch/*"><MerchProductPage {...props} /></Route>
      <Route path="/test/*"><MerchProductPage {...props} /></Route>
      <Route path="/cart"><Cart {...props} /></Route>
      <Route path="/account/register"><Signup {...props} /></Route>
      <Route path="/account/login"><Login {...props} /></Route>
      <Route path="/account/activate/*"><ActivateAccount {...props} /></Route>
      <Route path="/account/reset/*"><ResetPassword {...props} /></Route>
      <Route exact path="/tools/recurring/*"><Recharge {...props} variant="private" /></Route>
      <Route exact path="/account"><CustomerProfile {...props} variant="private" /></Route>
      <Route exact path="/blogs/*/*"><BlogDetail {...props} /></Route>
      <Route exact path="/blogs/*"><BlogList {...props} /></Route>
      <Route exact path="/headliners"><HeadlinersList /></Route>
      <Route exact path="/pages/headliners"><HeadlinersList /></Route>
      <Route path="/headliners/*"><HeadlinerProfile /></Route>
      <Route path="/pages/headliners/*"><HeadlinerProfile /></Route>
      <Route exact path="/experiences/auctions"><ExperiencesCategory type="AUCTION" /></Route>
      <Route exact path="/experiences/nfts"><ExperiencesCategory type="NFT" /></Route>
      <Route exact path="/experiences/sweeps"><ExperiencesCategory type="SWEEPS" /></Route>
      <Route exact path="/experiences"><Marketplace /></Route>
      <Route path="/experiences/*"><Marketplace /></Route>
      <Route exact path="/pages/grandprizeentry"><GrandPrizeEntryForm {...props} type="grandprizes" /></Route>
      <Route exact path="/pages/fwbprizeentry"><GrandPrizeEntryForm {...props} type="nfts" /></Route>
      <Route path="/pages/partners/*"><PartnerProfile {...props} /></Route>
      <Route path="/partners/*"><PartnerProfile {...props} /></Route>
      <Route path="/pages/fans/*"><CustomerProfile {...props} /></Route>
      <Route path="/fans/*"><CustomerProfile {...props} /></Route>
      <Route path="/pages/nonprofits/*"><NonprofitProfile {...props} /></Route>
      <Route path="/nonprofits/*"><NonprofitProfile {...props} /></Route>
      <Route path="/pages/about"><About {...props} /></Route>
      <Route path="/about"><About {...props} /></Route>
      <Route path="/pages/how-it-works"><HowItWorksPage {...props} /></Route>
      <Route path="/how-it-works"><HowItWorksPage {...props} /></Route>
      <Route path="/pages/official-rules"><OfficialRules {...props} /></Route>
      <Route path="/pages/official-rules-mx"><SpanishRules {...props} /></Route>
      <Route path="/pages/official-rules-mx-wwe-liv-morgan"><MxWweLivMorganRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-pearl-jam-msg"><MxPearljamRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-green-day-dc"><MxGreenDayRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-sonic-temple-corey-taylor"><MxSonicTempleCoreyTaylorRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-cage-the-elephant-msg-2024"><MxCageTheElephantMsg2024 {...props}/></Route>
      <Route path="/pages/official-rules-mx-papa-roach-bbq-2024"><MxPapaRoachBbq2024 {...props}/></Route>
      <Route path="/pages/official-rules-mx-the-killers-london-2024"><MxKillersLondon2024 {...props}/></Route>
      <Route path="/pages/official-rules-mx-metallica-guitar-24"><MxMetallicaGuitar24 {...props}/></Route>
      <Route path="/pages/official-rules-mx-x-games-ventura"><MxXGamesVentura {...props}/></Route>
      <Route path="/pages/official-rules-blink182-scotland-24"><BlinkCitiFieldRules {...props}/></Route>
      <Route path="/pages/official-rules-greta-van-fleet-london-24-mx"><GretaVanFleetLondon24mxRules {...props}/></Route>
      <Route path="/pages/official-rules-ringo-starr-bday-los-angeles-24"><RingoStarBdayRules {...props}/></Route>
      <Route path="/pages/official-rules-motley-crue-florida-24"><MotelyCrueFlorida {...props}/></Route>
      <Route path="/pages/official-rules-metallica-fortnite-24"><MetallicaFortniteRules {...props}/></Route>
      <Route path="/pages/official-rules-mexico-simple-plan-24"><MexicoSimplePlanRules {...props}/></Route>
      <Route path="/pages/official-rules-mexico-metallica-mexico-city-24"><MexicoMetallica {...props}/></Route>
      <Route path="/pages/official-rules-mexico-ohana-festival-2024"><OhanaFestivalRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-lamb-of-god-headbangers-24"><LambOFGodRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-korn-30th-anniversary-los-angeles-24"><KornRules {...props}/></Route>
      <Route path="/pages/official-rules-mx-ptv-wwwy-festival-24"><PTVRules {...props} /></Route>
      <Route path="/pages/official-rules-ushder-miami-2024"><UshderMiamiRules {...props} /></Route>
      <Route path="/pages/official-rules-uk"><UKRules {...props} /></Route>
      <Route path="/pages/cookie-policy"><CookiesPolicy {...props} /></Route>
      <Route path="/pages/official-rules-ca"><CanadaRules {...props} /></Route>
      <Route path="/pages/official-rules-ca-fr"><CanadaFrenchRules {...props} /></Route>
      <Route path="/pages/faqs"><Faq {...props} /></Route>
      <Route path="/pages/our-team"><Team {...props} /></Route>
      <Route path="/our-team"><Team {...props} /></Route>
      <Route path="/pages/nonprofit"><Charity {...props} /></Route>
      <Route path="/nonprofit"><Charity {...props} /></Route>
      <Route path="/pages/privacy-policy"><PrivacyPolicy {...props} /></Route>
      <Route path="/pages/privacy-policy-mx"><SpanishPrivacyPolicy {...props} /></Route>
      <Route path="/pages/privacy-policy-ca"><CanadaPrivacyPolicy {...props} /></Route>
      <Route path="/pages/privacy-policy-ca-fr"><CanadaFrenchPrivacyPolicy {...props} /></Route>
      <Route path="/pages/privacy-policy-uk"><UKPrivacyPolicy {...props} /></Route>
      <Route path="/pages/terms-of-use"><TermsOfUse {...props} /></Route>
      <Route path="/challenge"><Challenge {...props} /></Route>
      <Route path="/pages/club"><SubscriptionLanding {...props} /></Route>
      <Route path="/club"><SubscriptionLanding {...props} /></Route>
      <Route path="/pages/enterwithoutdonating"><EnterWithoutDonating {...props} /></Route>
      <Route path="/pages/42d873dc-3f31-11eb-b378-0242ac130002"><OrderAnalytics {...props} /></Route>
      <Route path="/winners"><PastWinners {...props} /></Route>
      <Route path="/pages/winners"><PastWinners {...props} /></Route>
      <Route path="/pages/do-not-sell-my-personal-information"><OptOutCalifornia {...props} /></Route>
      <Route exact path="/"><Home {...props} /></Route>
      <Route path="*"><FourOhFour {...props} /></Route>
    </Switch>
  ), 'pages');

  document.addEventListener('shopify:section:load', () => {
    factory.render();
  });
  document.addEventListener('shopify:section:unload', () => {
    factory.render();
  });
  document.addEventListener('shopify:section:reorder', () => {
    factory.render();
  });

  factory.render();
};

document.addEventListener('DOMContentLoaded', () => renderReact());

serviceWorker.unregister();
