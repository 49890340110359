import React from 'react';
import { TextField } from 'final-form-material-ui';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import classes from './index.module.scss';

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: "'Mazzard', sans-serif",
    },
  },
});

function MuiTextField({ ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        FormHelperTextProps={{
          className: classes.helper_text,
        }}
        InputProps={{
          classes: {
            root: classes.text_field,
            notchedOutline: classes.notchedOutline,
          },
        }}
        {...props}
      />
    </ThemeProvider>
  );
}

export default MuiTextField;
