import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import labelClasses from '../Typography/Label2/index.module.scss';

const CustomInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '74px',
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#E8EAED' : '#2b2b2b',
    border: 'none',
    fontSize: '16px !important',
    padding: '16px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:-webkit-autofill': {
      '&:first-line': {
        fontSize: '16px !important',
        fontFamily: "'Mazzard', sans-serif",

      },
      '&:focus': {
        fontSize: '16px !important',
        fontFamily: "'Mazzard', sans-serif",

      },
      '&:hover': {
        fontSize: '16px !important',
        fontFamily: "'Mazzard', sans-serif",

      },
      fontSize: '16px !important',
      fontFamily: "'Mazzard', sans-serif",

    },
    fontFamily: "'Mazzard', sans-serif",
  },
}));

function MuiTextField({ ...props }) {
  return (
    <FormControl sx={{ width: '100%', fontFamily: "'Mazzard', sans-serif" }} error={props.meta.error && props.meta.touched} variant="standard">
      <InputLabel
        sx={{
          fontFamily: "'Mazzard', sans-serif",
          ml: 1,
          fontSize: '18px',
          letterSpacing: '0.12em',
          textTransform: 'uppercase',
          fontWeight: '600',
          color: 'black',
        }}
        className={labelClasses.element}
        shrink
      >
        {props.label}
      </InputLabel>
      <CustomInput
        fullWidth
        {...props.input}
        {...props}
      />
      <FormHelperText sx={{ ml: 1, fontFamily: "'Mazzard', sans-serif" }}>
        {props.meta.touched && props.meta.error}

      </FormHelperText>
    </FormControl>
  );
}

MuiTextField.defaultProps = {
  label: '',
};

MuiTextField.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOf([PropTypes.string, null]),
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
};

export default MuiTextField;
