/* eslint-disable no-unused-vars */
export default function useKlaviyo(items) {
  const onClick = (e) => {
    try {
      window._Sendlane = window._Sendlane || [];
      const { href } = e.currentTarget;

      if (href) {
        const url = new URL(`https://fandiem.com${href}`);
        const params = new URLSearchParams(url.search);
        const id = params.get('id');
        const item = items.find((option) => option.variant_id === id);
        window.geq.addToCart(window.item);

        // Send sendlane events
        window._Sendlane.push({
          event: 'added_to_cart',
          product_id: window.item.ProductID,
          product_name: window.item.Name,
          image_url: window.item.ImageURL,
          categories: window.item.Categories,
          brand: window.item.Brand,
          price: window.item.Price,
          product_url: window.item.URL,
          compare_at_price: window.item.CompareAtPrice,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return onClick;
}
