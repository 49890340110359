import React from 'react';
import PropTypes from 'prop-types';
import {
// useSpring,
  animated,
// easings,
} from 'react-spring';
import useProgressiveImg from '../util/useProgressiveImg';

function ResponsiveImage({
  src: originalSrc, sizes, fallback, parallax, profileBanner, ...props
}) {
  const [src, { blur, loaded }] = useProgressiveImg(originalSrc, sizes);
  // const styles = useSpring({
  //   filter: blur ? 'blur(20px)' : 'blur(0px)',
  //   config: {
  //     duration: 300,
  //     easing: easings.easeOutElastic,
  //   },
  // });
  const backgroundSizePropertyDesktop = profileBanner ? '100% auto' : 'contain';
  const backgroundSizePropertyMobile = profileBanner ? '100%' : 'auto';

  return (
    // eslint-disable-next-line no-nested-ternary
    fallback && !loaded ? fallback : (
      parallax
        ? (
          <div
            style={{
              paddingBottom: window.innerWidth > 700 ? '27%' : '50%',
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
            }}
          >
            <div
              style={{
                ...props.style,
                backgroundImage: `url("${src}")`,
                backgroundAttachment: 'fixed',
                backgroundRepeat: 'no-repeat',
                backgroundSize: window.innerWidth > 700
                  ? backgroundSizePropertyDesktop : backgroundSizePropertyMobile,
                boxSizing: 'unset',
                backgroundPosition: window.innerWidth > 700 ? '' : 'center 82px',
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
              }}
            />
          </div>
        )
        : (
          <animated.img
            alt=""
            {...props}
            src={src}
            style={{
              ...props.style,
              background: blur ? 'rgba(0,0,0,0.11)' : 'transparent',
              // ...styles,
            }}
          />
        )
    )
  );
}

ResponsiveImage.defaultProps = {
  fallback: null,
  sizes: {},
  parallax: false,
  profileBanner: false,
};

ResponsiveImage.propTypes = {
  fallback: PropTypes.node,
  src: PropTypes.string.isRequired,
  sizes: PropTypes.shape({}),
  style: PropTypes.shape({}).isRequired,
  parallax: PropTypes.bool,
  profileBanner: PropTypes.bool,
};

export default ResponsiveImage;
