import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { TextField } from 'mui-rff';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { FORM_ERROR } from 'final-form';
import axios from 'axios';
import loadScript from 'scriptjs';
import Button from '../Button';
import classes from './index.module.scss';
import api from '../../../util/api';

function serialize(values) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    acc += `${key}=${value}&`; // eslint-disable-line
    return acc;
  }, '');
}

function _loadScript(scripts) {
  return new Promise((resolve) => {
    loadScript(scripts, () => {
      resolve();
    });
  });
}

function InputAdapter({
  input,
  meta,
  invalid,
  valid,
  ...rest
}) {
  return (
    <FormControl
      {...input}
      {...rest}
      invalid={invalid(meta).toString()}
      isValid={valid(meta).toString()}
    />
  );
}

InputAdapter.defaultProps = {
  invalid: (innerMeta) => innerMeta.touched && innerMeta.invalid,
  valid: () => false,
};

InputAdapter.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({}).isRequired,
  invalid: PropTypes.func,
  valid: PropTypes.func,
};

function EmailCapture({
  url, mailChimpToken, redirect, mailchimp,
}) {
  const [showCaptcha, updateShowCaptcha] = useState(false);
  const formRef = useRef();
  const recaptchaRef = React.useRef();

  const handleOpen = (e) => {
    e.preventDefault();
    updateShowCaptcha(true);
  };

  const handleClose = () => {
    updateShowCaptcha(false);
  };

  const callback = () => {
    console.log('SUBSCRIBED');
  };

  const onSubmit = async (values) => { // eslint-disable-line
    if (mailchimp) {
      if (!values.EMAIL) {
        const errors = {};
        errors[FORM_ERROR] = 'Email Is Required';
        return errors;
      }
    } else if (!values.email) {
      const errors = {};
      errors[FORM_ERROR] = 'Email Is Required';
      return errors;
    }

    try {
      if (mailchimp) {
        const token = recaptchaRef.current.getValue();
        await axios.post('https://warm-cove-11322.herokuapp.com/api/users/challenge', {
          ...values,
          captcha_token: token,
        });
      }
    } catch (e) {
      console.log(e);
      return { [FORM_ERROR]: 'ReCAPTCHA required. Please try again.' };
    }
    try {
      if (mailchimp) {
        if (url !== 'https://fandiem.us7.list-manage.com/subscribe/post-json?u=362a74c79c9078e0487914798&amp;id=2b4d5fdbc5') {
          const fandiemSerializedForm = serialize({
            b_362a74c79c9078e0487914798_2b4d5fdbc5: '',
            ...values,
            subscribe: 'Subscribe',
          });
          await _loadScript(`https://fandiem.us7.list-manage.com/subscribe/post-json?u=362a74c79c9078e0487914798&amp;id=2b4d5fdbc5&${fandiemSerializedForm}c=${callback}`);
        }

        const serializedForm = serialize({
          [mailChimpToken]: '',
          ...values,
          subscribe: 'Subscribe',
        });

        await _loadScript(`${url}&${serializedForm}c=${callback}`);
      } else {
        const token = recaptchaRef.current.getValue();

        await axios.post(`${api}/accounts/newsletter`, {
          ...values,
          captcha_token: token,
        });
        window.location.reload();
      }

      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.replace('https://fandiem.com/?customer_posted=true');
        return false;
      }
    } catch (e) {
      return { email: 'Something went wrong. Please refresh the page and try again.' };
    }
  };

  const buttonProps = mailchimp ? {
    name: 'subscribe',
    value: 'Subscribe',
    onClick: handleOpen,
    type: 'button',
  } : {
    type: 'submit',
    name: 'commit',
  };
  const emailName = mailchimp ? 'EMAIL' : 'email';

  const render = ({
    handleSubmit, submitting, submitError,
  }) => (
    <form id="emailForm" ref={formRef} onSubmit={handleSubmit} className={classes.form}>
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <TextField name={mailChimpToken} tabIndex="-1" />
      </div>

      <div className={classes.row}>
        <Field
          component={InputAdapter}
          type="email"
          name={emailName}
          id="ContactFooter-email"
          placeholder="john@doe.com"
          aria-label="john@doe.com"
          aria-required="true"
          required=""
          autoCorrect="off"
          autoCapitalize="off"
        />
        <Button
          onClick={handleOpen}
          {...buttonProps}
          variant="pink_blue"
        >
          Subscribe
        </Button>
      </div>

      <Modal show={showCaptcha} onHide={handleClose} className="m-3">
        <Modal.Body className="p-4">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LevEgcaAAAAAH95ePqurCRug6C8H3iUveYm05xL"
          />
          <br />
          {submitError && <div className="text-red">{submitError}</div>}
          <br />
          <Button
            form="emailForm"
            variant="pink_blue"
            disabled={submitting}
            type="submit"
            className="px-4 py-3 text-uppercase"
          >
            Subscribe

          </Button>
        </Modal.Body>
      </Modal>
    </form>
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={render}
    />
  );
}

EmailCapture.defaultProps = {
  url: 'https://fandiem.us7.list-manage.com/subscribe/post-json?u=362a74c79c9078e0487914798&amp;id=2b4d5fdbc5',
  mailChimpToken: 'b_362a74c79c9078e0487914798_2b4d5fdbc5',
  redirect: null,
  mailchimp: false,
};

EmailCapture.propTypes = {
  mailchimp: PropTypes.bool,
  redirect: PropTypes.string,
  url: PropTypes.string,
  mailChimpToken: PropTypes.string,
};

export default EmailCapture;
