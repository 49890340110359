import React from 'react';
import Timer from 'react-countdown';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Clock from './Clock';
import classes from './index.module.scss';

function Countdown({ date, ...props }) {
  return (
    <Timer
      renderer={({
        days, hours, minutes, seconds,
      }) => (
        <div {...props} className={`${classes.countdown} text-uppercase`}>
          <div className={classes.countdown__clock}>
            <Clock />
          </div>
          <div className={classes.countdown__it_ends}>
            Ends in
          </div>
          <div className={classes.countdown__timer_wrapper}>
            <div className={classes.countdown__number_wrapper}>
              <div className={classes.countdown__number}>
                {(days * 24) + hours}
              </div>
              <div className={classes.countdown__number_label}>
                hrs
              </div>
            </div>
            <div className={classes.countdown__number_wrapper}>
              <div className={classes.countdown__number}>
                {minutes}
              </div>
              <div className={classes.countdown__number_label}>
                min
              </div>
            </div>
            <div className={classes.countdown__number_wrapper}>
              <div className={classes.countdown__number}>
                {seconds}
              </div>
              <div className={classes.countdown__number_label}>
                secs
              </div>
            </div>
          </div>
          <div />
        </div>
      )}
      daysInHours
      date={date.toDate()}
    />
  );
}

Countdown.propTypes = {
  date: PropTypes.instanceOf(dayjs).isRequired,
};

export default Countdown;
