import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Box, Typography } from '@mui/material';
import classes from '../pages/AuctionDetail/index.module.scss';
import Label from './Typography/Label2';
import CaretIcon from './Icons/CaretLarge';

function Fallback({ mode }) {
  return (
    <div style={{ zIndex: '-1', position: 'relative', backgroundColor: mode === 'light' ? '#fff' : '#0B0B16' }}>
      <div className={classes.cover_image}>
        <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="rectangular" animation={false} width="100%" height="100%" />
      </div>
      <Box justifyContent="center" sx={{ mx: 'auto' }} className={classes.top_wrapper}>
        <Box justifySelf="center" gridColumn="1/-1" gridTemplateColumns={{ xs: 'auto', lg: '640px auto' }} gridTemplateRows={{ xs: 'auto auto', lg: 'auto' }} className={classes.top}>
          <Box gridColumn="1/2" gridRow="1/2" sx={{ width: '100' }} className={`position-relative ${classes.image_gallery_wrapper}`}>
            <div style={{ paddingTop: '100%', width: '100%' }} className="position-relative">
              <div className="aspect-ratio-box-inside">
                <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="rectangular" animation={false} width="100%" height="100%" />
              </div>
            </div>
          </Box>

          <Box gridRow={{ xs: '2/3', lg: '1/2' }} alignSelf="start" gridColumn={{ lg: '2/3', xs: '1/2' }} display="grid" gridTemplateColumns="auto" className={classes.content}>
            <Box gridRow="span 1" display="grid" gridTemplateColumns="auto auto" gap={2}>
              <Box gridColumn="span 1" gridRow="1/2" justifySelf="start" alignSelf="center">
                <Typography variant="caption">
                  <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="text" animation={false} width="20px" height="100%" />
                </Typography>
                {' '}

              </Box>
              <Box gridColumn="2/3" gridRow="1/2" justifySelf="end" alignContent="center">
                <div id="action_buttons" className={classes.action_buttons}>
                  <Typography variant="caption">
                    <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="text" animation={false} width="100px" height="100%" />
                  </Typography>
                  <div className={`${classes.action} ${classes.action__like}`}>
                    <Typography variant="caption">
                      <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="text" animation={false} width="100px" height="100%" />
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
            <Box gridRow="span 1">
              <Label className={`text-uppercase ${classes.charities_text}`}>
                <Typography variant="h3">
                  <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="text" animation={false} width="80%" height="100%" />
                </Typography>

              </Label>
            </Box>
            <Box gridRow="span 1">
              <Typography variant="h1">
                <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="text" animation={false} width="100%" height="100%" />
              </Typography>
            </Box>
            <Box gridRow="span 1" id="auction-wrapper">
              <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="rectangular" animation={false} width="100%" height="200px" />
            </Box>
            <Box gridRow="span 1" display="grid" justifyContent="start" alignItems="center" gridTemplateColumns="auto auto" gridTemplateRow="auto" gap={2} className={classes.profile_wrapper}>
              <Box
                gridColumn="span 1"
              >
                <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="circular" animation={false} width="55px" height="55px" />
              </Box>
              <Box gridColumn="span 1">
                <Typography variant="caption">
                  <Skeleton sx={{ backgroundColor: 'rgba(239, 239, 239, 0.85)' }} variant="text" animation={false} width="100px" height="100%" />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="1/-1" display="grid" gridTemplateColumns="minmax(auto, 736px)" justifyContent="center" rowGap={2} className={classes.mid}>
          <Box sx={{ background: 'rgba(239, 239, 239, 0.85)', borderRadius: '8px' }} gridColumn="1/-1" display="grid" gridRow="span 1" gridTemplateColumns="1fr minmax(auto, 640px) 1fr" className={classes.accordion}>
            <Box
              sx={{ cursor: 'pointer' }}
              gridRow="span 1"
              gridColumn="1/-1"
              display="grid"
              className={classes.toggle}
            >
              <Box gridColumn="1/2" justifySelf="start">....</Box>
              <Box gridColumn="2/3" justifySelf="end">
                <div className={classes.accordion_content}>
                  <CaretIcon />
                </div>
              </Box>
            </Box>
          </Box>

          <Box sx={{ background: 'rgba(239, 239, 239, 0.85)', borderRadius: '8px' }} gridColumn="1/-1" display="grid" gridRow="span 1" gridTemplateColumns="1fr minmax(auto, 640px) 1fr" className={classes.accordion}>
            <Box
              sx={{ cursor: 'pointer' }}
              gridRow="span 1"
              gridColumn="1/-1"
              display="grid"
              className={classes.toggle}
            >
              <Box gridColumn="1/2" justifySelf="start">....</Box>
              <Box gridColumn="2/3" justifySelf="end">
                <div className={classes.accordion_content}>
                  <CaretIcon />
                </div>
              </Box>
            </Box>

          </Box>
          <Box sx={{ background: 'rgba(239, 239, 239, 0.85)', borderRadius: '8px' }} gridColumn="1/-1" display="grid" gridRow="span 1" gridTemplateColumns="1fr minmax(auto, 640px) 1fr" className={classes.accordion}>
            <Box
              sx={{ cursor: 'pointer' }}
              gridRow="span 1"
              gridColumn="1/-1"
              display="grid"
              className={classes.toggle}
            >
              <Box gridColumn="1/2" justifySelf="start">....</Box>
              <Box gridColumn="2/3" justifySelf="end">
                <div className={classes.accordion_content}>
                  <CaretIcon />
                </div>
              </Box>
            </Box>

          </Box>
        </Box>
      </Box>
    </div>
  );
}

Fallback.defaultProps = {
  mode: 'light',
};

Fallback.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark']),
};

export default Fallback;
