import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, Field } from 'react-final-form';
import BeatLoader from 'react-spinners/BeatLoader';
import usePost from 'use-http';
import classes from './index.module.scss';
import Button from '../../../../components/Button';
import api from '../../../../../util/api';

function ForgotPassword() {
  const [openModal, setOpenModal] = useState(false);
  const {
    loading, response, error, post, data,
  } = usePost(api);
  // const [successMessage, setSuccessMessage] = useState('');

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    return errors;
  };

  const onSubmit = (values) => {
    // /api/accounts/sendPasswordReset
    post('/accounts/sendPasswordReset', values);
  };

  return (
    <>
      <div className={classes.forgot_password}>
        <button
          type="button"
          onClick={() => setOpenModal(true)}
        >
          Forgot password?
        </button>
      </div>

      <Modal show={openModal} onHide={() => setOpenModal(false)} className={classes.modal}>
        <Modal.Body>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.form_group}>
                  <div className={classes.label}>EMAIL ADDRESS</div>
                  <Field
                    component="input"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    placeholder="john@doe.com"
                  />
                </div>
                <div className={classes.button_wrapper}>
                  <Button
                    disabled={submitting || loading}
                    type="submit"
                    variant="primary"
                    className={`${classes.submit}`}
                    fixOffset
                  >
                    {submitting || loading ? (
                      <BeatLoader
                        css={{
                          top: '0.3em',
                          position: 'relative',
                        }}
                        size={15}
                        color="#fff"
                      />
                    ) : 'Send password reset link'}
                  </Button>
                  <br />
                  <br />
                  {response && response.ok && values.email && (
                  <span style={{ color: 'green' }}>
                    Reset link sent to
                    {' '}
                    {values.email}
                    <br />
                  </span>

                  )}
                  {error && (
                  <span style={{ color: 'red' }}>
                    {data.error || error.message}
                    <br />
                  </span>
                  )}
                </div>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPassword;
