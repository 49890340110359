/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import subscriptionFaq from '../../../../../fixtures/directDonationFaq';
import H3 from '../../../../components/Typography/H3/index';
import Accordion from './components/Accordion.js';
import classes from './index.module.scss';
// import Button from '../../../../components/Button';

function FAQ(props) {
  // const [showRest, setShowRest] = React.useState(false);
  const first3 = subscriptionFaq.slice(0, 3);
  // const rest = subscriptionFaq.slice(3);
  return (
    <Box {...props} className={classes.container}>
      <div className={classes.faqs_wrapper}>
        <H3 style={{ textAlign: 'center' }} className={classes.section_title}>Frequently Asked Questions</H3>
        <div className={`${classes.faqs}`}>
          {first3.map((item, index) => <Accordion key={index} defaultOpen {...item} />)}
        </div>
        {/* {showRest ? (
          <div className={`${classes.faqs}`}>
            {rest.map((item, index) => <Accordion key={index} defaultOpen {...item} />)}
          </div>
        ) : (
          rest.length ? (
            <Box display="grid" sx={{ p: 2 }}>
              <Button style={{ justifySelf: 'center', margin: 'auto', textAlign: 'center' }} variant="primary" onClick={() => setShowRest(true)}>
                See More
              </Button>
            </Box>
          ) : null
        ) } */}
      </div>
    </Box>
  );
}

FAQ.propTypes = {};

export default FAQ;
