import React from 'react';
import PropTypes from 'prop-types';

function CaretSmallIcon({ variant }) {
  const fillColor = variant === 'light' ? 'white' : '#171729';

  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L5 5L9 1"
        stroke={fillColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CaretSmallIcon.defaultProps = {
  variant: 'dark',
};

CaretSmallIcon.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export default CaretSmallIcon;
