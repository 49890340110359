import React, { useState } from "react";
import hasWebp from "../../../util/hasWebp";
import classes from "./index.module.scss";
import EmailCapture from "../EmailCapture";
import logo from "../../../images/logo-red-yellow.png";
import FacebookIcon from "../Icons/Facebook";
import TwitterIcon from "../Icons/Twitter";
import InstagramIcon from "../Icons/Instagram";
import Tiktokwebp from "../../../images/tiktok.webp";
import Tiktokpng from "../../../images/tiktok.png";
import LinkedInLogo from "../../../images/linkedin-logo.png";
import DiscordLogo from "../../../images/discord.png";
import useFetch from "../../../util/useFetch";

function NavFooter() {
  const [isDropDown, setIsDropDown] = useState(false);
  const { res: exploreRes } = useFetch(
    "https://fandiem.com/pages/menus/explore"
  );
  const { res: legalRes } = useFetch("https://fandiem.com/pages/menus/legal");
  const { res: donateRes } = useFetch("https://fandiem.com/pages/menus/donate");
  const { res: aboutRes } = useFetch("https://fandiem.com/pages/menus/about");

  let exploreLinks = [];
  let legalLinks = [];
  let donateLinks = [];
  let aboutLinks = [];

  if (exploreRes && exploreRes.data) {
    const { data } = exploreRes;
    exploreLinks = data.explore;
  }

  if (legalRes && legalRes.data) {
    const { data } = legalRes;
    legalLinks = data.legal;
  }

  if (donateRes && donateRes.data) {
    const { data } = donateRes;
    donateLinks = data.donate;
  }

  if (aboutRes && aboutRes.data) {
    const { data } = aboutRes;
    aboutLinks = data.about;
  }

  const renderLinks = (links) =>
    links.map((link) => (
      <a href={link.url} key={link.id}>
        {link.title}
      </a>
    ));
  const dropDownMenu = () => {
    setIsDropDown(!isDropDown);
    console.log("is open working");
  };
  return (
    <footer className={classes.nav_footer}>
      <div className={classes.container}>
        <div className={`${classes.row} ${classes.logo_socials}`}>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          <div className={classes.socials}>
            <a href="https://facebook.com/winwithfandiem">
              <FacebookIcon />
            </a>
            <a href="https://twitter.com/winwithfandiem">
              <TwitterIcon />
            </a>
            <a href="https://instagram.com/winwithfandiem">
              <InstagramIcon />
            </a>
            <a href="http://tiktok.com/@winwithfandiem">
              <img
                style={{ alignSelf: "center" }}
                src={hasWebp() ? Tiktokwebp : Tiktokpng}
                alt="Tiktok"
              />
            </a>
            <a href="https://www.linkedin.com/company/fandiem/">
              <img
                style={{ alignSelf: "center" }}
                src={LinkedInLogo}
                alt="LinkedIn"
              />
            </a>
            <a href="https://discord.gg/y4FsjjbBex">
              <img
                style={{ alignSelf: "center" }}
                src={DiscordLogo}
                alt="Discord"
              />
            </a>
          </div>
        </div>

        <div className={`${classes.row} ${classes.copyright}`}>
          <p>© {new Date().getFullYear()} Fandiem Inc</p>
        </div>

        <div className={`${classes.row} ${classes.mailchimp_form}`}>
          <h2>
            Join the{" "}
            <span className="gradient_pink_blue_text">Fans With Benefits</span>{" "}
            <br />
            community
          </h2>
          <EmailCapture />
        </div>

        <div className={`${classes.row} ${classes.links} ${classes.donate}`}>
          <h3>Donate</h3>
          {renderLinks(donateLinks)}
        </div>

        <div className={`${classes.row} ${classes.links} ${classes.explore}`}>
          <h3>Explore</h3>
          {renderLinks(exploreLinks)}
        </div>

        <div className={`${classes.row} ${classes.links} ${classes.legal}`}>
        <div className={classes.legal_cursor} onClick={dropDownMenu}>
          <h3>
            Legal
          </h3>
        
          <svg style={{rotate:isDropDown? "0deg" :"180deg", transition:'0.3s ease all'}} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"></path></svg>
          </div>
          {isDropDown && renderLinks(legalLinks)}
        </div>

        <div className={`${classes.row} ${classes.links} ${classes.about}`}>
          <h3>About</h3>
          {renderLinks(aboutLinks)}
          <a href="mailto:info@fandiem.com?subject=I've got a question">
            Contact Us
          </a>
        </div>

        <div className={`${classes.row} ${classes.final_text}`}>
          <p>
            NO PURCHASE, PAYMENT, OR DONATION NECESSARY TO ENTER OR WIN. MAKING
            A PURCHASE PAYMENT, OR DONATION OF ANY KIND WILL NOT INCREASE YOUR
            CHANCE OF WINNING A PRIZE. VOID WHERE PROHIBITED BY LAW.
          </p>
        </div>
      </div>
    </footer>
  );
}

NavFooter.defaultProps = {};

NavFooter.propTypes = {};

export default NavFooter;
