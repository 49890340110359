import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { render } from 'react-dom';
import { uniqueId } from 'lodash';
import * as Sentry from '@sentry/react';
import LoadingScreen from './components/LoadingScreen';

const ShopifyWrapper = React.lazy(() => import('./ShopifyWrapper'));
const Fallback = React.lazy(() => import('./components/Fallback'));

// const Portal = ({ children, domNode }) => createPortal(
//   children,
//   domNode,
// );

export default class ReactFactory {
  constructor() {
    this.children = [];
    this.mountNode = document.getElementById('react-root');
    this.data = JSON.parse(this.mountNode.getAttribute('data'));
  }

  addComponent(Component, elementId, path, additionalData) {
    let { data } = this;
    if (additionalData) data = { ...data, ...additionalData };
    const Wrapper = path ? Route : React.Fragment;
    this.children.push(
      path ? (
        <Wrapper key={path} path={path} exact>
          <Component {...data} />
        </Wrapper>
      ) : (
        <Component key={uniqueId()} {...data} />
      ),

    );
  }

  // addComponents(Component, elementId, path) {
  //   const mountArr = Array.from(document.getElementsByClassName(elementId));
  //   if (mountArr.length) {
  //     mountArr.forEach((node) => {
  //       if (!node.firstElementChild) {
  //         const mountNode = node;
  //         const data = JSON.parse(mountNode.getAttribute('data'));
  //         const Wrapper = path ? Route : React.Fragment;
  //         this.children.push(
  //           <Wrapper path={path}>
  //             <Portal domNode={mountNode}>
  //               <Component {...data} />
  //             </Portal>
  //           </Wrapper>,
  //         );
  //       }
  //     });
  //   }
  // }

  render() {
    const { mountNode } = this;

    Sentry.init({
      dsn: 'https://83b4b83b73414522ab32c9f01302abc9@o4504566872735745.ingest.sentry.io/4505563011612672',
      integrations: [
        new Sentry.BrowserTracing({
          // eslint-disable-next-line max-len
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      // eslint-disable-next-line max-len
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // eslint-disable-next-line max-len
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    const App = (
      <Suspense fallback={<LoadingScreen />}>
        <ShopifyWrapper>
          <Sentry.ErrorBoundary fallback={Fallback}>
            {this.children.map((child) => child)}
          </Sentry.ErrorBoundary>
        </ShopifyWrapper>
      </Suspense>
    );

    render(
      App,
      mountNode,
    );
  }
}
