import React from 'react';
import PropTypes from 'prop-types';

function CaretLargeIcon({ variant }) {
  let fillColor = '#171729';

  switch (variant) {
    case 'light':
      fillColor = 'white';
      break;
    case 'purple':
      fillColor = '#5F2AF7';
      break;
    default:
      fillColor = '#171729';
  }

  return (
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.73828 1L7.73828 7L13.7383 1" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

CaretLargeIcon.defaultProps = {
  variant: 'dark',
};

CaretLargeIcon.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark', 'purple']),
};

export default CaretLargeIcon;
