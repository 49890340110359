import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(6),
    minWidth: 180,
    background: '#171729',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '16px 0',
      '& a': {
        color: '#ffffff',
        fontSize: '14px',
      },
    },
    '& .MuiMenu-list a': {
      fontFamily: 'Mazzard, san-serif',
      letterSpacing: '1px',
    },
  },
}));

export default StyledMenu;
