import React from 'react';
import PropTypes from 'prop-types';
import { InView } from '../V2/components/IntersectionObserver';
import ResponsiveImage from './ResponsiveImage';

const S3StaticDomain = 'https://d1tkzf9ss7ctgm.cloudfront.net';
const S3StorageDomain = 'https://fandiem-storage.s3.us-east-2.amazonaws.com';

function Media({
  media,
  className,
  videoStyle,
  imageStyle,
  style,
  controls,
  disableView,
  fallback,
  parallax,
  profileBanner,
  ...props
}) {
  // const isSafari = navigator.userAgent.indexOf('Safari') > -1
  // && navigator.userAgent.indexOf('Chrome') === -1;

  /*
  isSafari
  ? <img src={media.src} style={{ ...style, ...videoStyle }} {...props} />
:
   */

  if (!media.src) {
    return <div {...props} />;
  }

  const combinedStyle = media.contentType === 'video/mp4' || media.contentType === 'video/quicktime'
    ? { ...style, ...videoStyle, overflow: 'hidden' }
    : { ...style, ...imageStyle, overflow: 'hidden' };

  const formattedSrc = media.src ? media.src.replace(S3StorageDomain, S3StaticDomain) : null;
  const renderVideo = (
    <video
      autoPlay
      loop
      muted
      playsInline
      className="w-100 h-100"
      controls={controls}
      {...props}
      style={{ objectFit: 'inherit', backgroundColor: 'rgba(0,0,0,0.11)' }}
    >
      <source src={formattedSrc} />
      Your browser does not support the video tag.
    </video>
  );

  const renderMedia = (

    media.contentType === 'video/mp4' || media.contentType === 'video/quicktime'
      ? renderVideo
      : <ResponsiveImage profileBanner={profileBanner} parallax={parallax} fallback={fallback} src={media.src} {...props} className="w-100 h-100" style={{ objectFit: 'inherit' }} />

  );

  return (
    <InView options={{ threshold: 0.2 }}>
      {({ inView, ref }) => (
        <div ref={ref} style={combinedStyle} className={className}>

          { // eslint-disable-next-line no-nested-ternary
        !inView && !disableView
          ? (
            fallback ? <div {...props} className="w-100 h-100">{fallback}</div> : <div {...props} className="w-100 h-100" style={{ backgroundColor: 'rgba(0,0,0,0.11)' }} />
          )
          : renderMedia
          }
        </div>
      )}
    </InView>
  );
}

Media.defaultProps = {
  disableView: false,
  fallback: null,
  className: '',
  videoStyle: {},
  imageStyle: {},
  controls: false,
  style: {},
  parallax: false,
  profileBanner: false,
};

Media.propTypes = {
  media: PropTypes.shape({
    contentType: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  videoStyle: PropTypes.shape({}),
  imageStyle: PropTypes.shape({}),
  controls: PropTypes.bool,
  style: PropTypes.shape({}),
  disableView: PropTypes.bool,
  fallback: PropTypes.node,
  className: PropTypes.string,
  parallax: PropTypes.bool,
  profileBanner: PropTypes.bool,
};

export default Media;
