import React from 'react';

export default function () {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22.4355C17.5228 22.4355 22 17.9584 22 12.4355C22 6.9127 17.5228 2.43555 12 2.43555C6.47715 2.43555 2 6.9127 2 12.4355C2 17.9584 6.47715 22.4355 12 22.4355Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 6.43555V12.4355L16 14.4355" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
