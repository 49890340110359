import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import BeatLoader from 'react-spinners/BeatLoader';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import TextField from '../../../../components/MuiTextFieldAlt';
import SubscribeCheckbox from '../SubscribeCheckbox';
import Button from '../../../../components/Button';
// import PhoneInput from '../../../../../components/PhoneInput';
import classes from './index.module.scss';

const SignupForm = forwardRef(({ onSubmit, validate, activateRequired }, recaptchaRef) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={{
      accepts_marketing: false,
    }}
    render={({
      handleSubmit, submitting, submitError,
    }) => (
      <form onSubmit={handleSubmit} className={classes.form}>
        {activateRequired
          ? (
            <>
              <div className="font-size-4 font-weight-bold">
                <br />
                <br />
                Please copy the code in the email we just sent you and paste it here.
                {' '}
                It looks like you have already entered in a previous drop.
                {' '}
                Create your account so you can view your past entries!
                {' '}
                <br />
              </div>
              <div className={classes.form_group}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="token"
                  label="PASTE THE CODE HERE"
                  name="token"
                  autoComplete="token"
                  placeholder="PASTE CODE"
                />
              </div>
              {/* <Grid
                justify="center"
                alignItems="center"
                item
                xs={12}
              >
                {submitError && <div style={{ color: '#d32f2f' }}>{submitError}</div>}
              </Grid> */}

              <div className={`${classes.submit_btn}`}>
                <Button
                  disabled={submitting}
                  type="submit"
                  variant="primary"
                  className={`${classes.submit}`}
                >
                  {submitting ? (
                    <BeatLoader
                      css={{
                        top: '0.3em',
                        position: 'relative',
                      }}
                      size={15}
                      color="#fff"
                    />
                  ) : 'Create Account'}
                </Button>
              </div>

            </>
          )
          : (
            <>
              <input type="hidden" name="form_type" value="create_customer" />
              <input type="hidden" name="utf8" value="✓" />
              {/* firstName, lastName, email, phone, password, confirmPassword */}
              {/* subscription marketing checkbox */}
              {/* Im not a robot */}
              {/* Create Account button */}
              {/* Already have an account? Sign in */}
              <div className={classes.form_group}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  autoComplete="given-name"
                  placeholder="First Name"
                />
              </div>
              <div className={classes.form_group}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="family-name"
                  placeholder="Last Name"
                />
              </div>
              <div className={classes.form_group}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  placeholder="Email Address"
                />
              </div>
              {/* <div className={classes.form_group}>
                <Field
                  component={PhoneInput}
                  variant="outlined"
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="tel"
                />
              </div> */}
              <div className={classes.form_group}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  placeholder="Password"
                />
              </div>
              <div className={classes.form_group}>
                <Field
                  component={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="password_confirmation"
                  label="Confirm Password"
                  name="password_confirmation"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                />
              </div>
              {/* <div className={`${classes.form_group} ${classes.subscribe}`}>
        <FormControlLabel
          control={(
            <Field
              type="checkbox"
              component={Checkbox}
              name="accepts_marketing"
              color="primary"
            />
          )}
          label="Subscribe to the newsletter"
        />
        <p>
          I want to receive new contest announcements,
          marketing promotions, and updates via email.
        </p>
      </div> */}
              <SubscribeCheckbox />

              <div className={classes.form_group}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LevEgcaAAAAAH95ePqurCRug6C8H3iUveYm05xL"
                />
              </div>

              <Grid
                justify="center"
                alignItems="center"
                item
                xs={12}
              >
                {submitError && <div style={{ color: '#d32f2f' }}>{submitError}</div>}
              </Grid>

              <div className={`${classes.submit_btn}`}>
                <Button
                  disabled={submitting}
                  type="submit"
                  variant="primary"
                  className={`${classes.submit}`}
                >
                  {submitting ? (
                    <BeatLoader
                      css={{
                        top: '0.3em',
                        position: 'relative',
                      }}
                      size={15}
                      color="#fff"
                    />
                  ) : 'Create Account'}
                </Button>
              </div>
            </>
          )}
      </form>
    )}
  />
));

SignupForm.defaultProps = {
  activateRequired: false,
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  activateRequired: PropTypes.bool,
};

export default SignupForm;
