// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sRGaCAvDR5aRmWYPxUS2{overflow-x:hidden;overflow-x:clip}\n", "",{"version":3,"sources":["webpack://./src/V2/layouts/Main.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CAClB,eAAgB","sourcesContent":[".main {\n  overflow-x: hidden;\n  overflow-x: clip;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "sRGaCAvDR5aRmWYPxUS2"
};
export default ___CSS_LOADER_EXPORT___;
