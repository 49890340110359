import React from 'react';
import PropTypes from 'prop-types';

function Element({
  Type, classes, className, ...props
}) {
  return <Type {...props} className={`${classes.element} ${className ?? ''}`} />;
}

Element.defaultProps = {
  className: undefined,
  Type: 'div',
  classes: {
    element: '',
  },
};

Element.propTypes = {
  Type: PropTypes.elementType,
  className: PropTypes.string,
  classes: PropTypes.shape({
    element: PropTypes.string,
  }),
};

export default Element;
