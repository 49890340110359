import React from 'react';
import './LoadingScreen.scss';
import getSrcSet from '../../util/getSrcSet';
import hasWebp from '../../util/hasWebp';

const logo = hasWebp() ? 'https://cdn.shopify.com/s/files/1/0502/2167/8757/files/logo.webp' : 'https://cdn.shopify.com/s/files/1/0502/2167/8757/files/logo';

function LoadingScreen() {
  return (
    <div style={{ zIndex: 1000 }} className="w-100 h-100 position-fixed fandiem-bg-white">
      <div className="w-100 h-100 loading_screen">
        <div className="abs-center width-2 width-lg-1">
          <img
            src={logo}
            className="w-100 h-auto"
            alt="logo"
            {...getSrcSet(logo, { xs: '15vw', sm: '12vw' })}
          />
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
