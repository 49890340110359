const S3StaticDomain = 'https://d1tkzf9ss7ctgm.cloudfront.net';
const S3StorageDomain = 'https://fandiem-storage.s3.us-east-2.amazonaws.com';
const ArenaCommerceDomain = 'https://cdn.arenacommerce.com';
const ShopifyCDNDomain = 'cdn.shopify.com';

export default function getSizedImage(src, size, ext) {
  if (src.includes(ShopifyCDNDomain)) {
    return src.replace(ext, `_${size}x${ext}`);
  }
  if (!src.startsWith('http')) {
    return src;
  }
  const url = new URL(src);
  if (url.origin === ArenaCommerceDomain) {
    return src;
  }
  if (url.origin === S3StorageDomain) {
    return src.replace(S3StorageDomain, `${S3StaticDomain}/${size}x`);
  }

  return src;
}
