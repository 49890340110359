import React from 'react';
import PropTypes from 'prop-types';

function FacebookIcon({ variant }) {
  const fillColor = variant === 'light' ? 'white' : '#171729';

  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_920_20446)">
        <path d="M32 16.7578C32 7.92125 24.8366 0.757812 16 0.757812C7.16344 0.757812 0 7.92125 0 16.7578C0 24.7438 5.85094 31.3631 13.5 32.5634V21.3828H9.4375V16.7578H13.5V13.2328C13.5 9.22281 15.8888 7.00781 19.5434 7.00781C21.2934 7.00781 23.125 7.32031 23.125 7.32031V11.2578H21.1075C19.12 11.2578 18.5 12.4913 18.5 13.7578V16.7578H22.9375L22.2281 21.3828H18.5V32.5634C26.1491 31.3631 32 24.7438 32 16.7578Z" fill={fillColor} />
      </g>
      <defs>
        <clipPath id="clip0_920_20446">
          <rect width="32" height="32" fill={fillColor} transform="translate(0 0.757812)" />
        </clipPath>
      </defs>
    </svg>
  );
}

FacebookIcon.defaultProps = {
  variant: 'light',
};

FacebookIcon.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export default FacebookIcon;
