import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import classes from './index.module.scss';

const FallbackCard = React.memo(() => (
  <div
    className={classes.fandiem_card}
    style={{
      display: 'grid', gap: '16px', borderRadius: '40px', padding: '16px',
    }}
  >
    <Box sx={{ borderRadius: '24px', overflow: 'hidden', paddingTop: '100%' }} className="w-100 position-relative">
      <div className={`aspect-ratio-box-inside ${classes.fandiem_card__image}`}>
        <div className="aspect-ratio-box-inside">
          <Skeleton animation={false} variant="rectangular" width="100%" height="100%" />
        </div>

      </div>
    </Box>
    <Box sx={{ width: '100%' }} justifySelf="start" alignSelf="end" display="grid" gridTemplateRows="auto auto" gridTemplateColumns="1fr" gap={2}>
      <Box gridRow="span 1" gridColumn="span 1">
        <Skeleton animation={false} variant="text" width="100%" />
      </Box>
      <Box gridRow="span 1" gridColumn="span 1">
        <Typography variant="h3">
          <Skeleton animation={false} variant="text" width="90%" />
        </Typography>
      </Box>
    </Box>
  </div>
));

export default FallbackCard;
