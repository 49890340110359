import PropTypes from 'prop-types';

export const Product = {
  title: PropTypes.string.isRequired,
  media: PropTypes.shape({
    src: PropTypes.string.isRequired,
    contentType: PropTypes.string,
  }).isRequired,
  featuredImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    contentType: PropTypes.string,
  }),
  sizes: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    xxl: PropTypes.string,
  }),
  deadline: PropTypes.string,
  deadline_time: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  charities: PropTypes.arrayOf(PropTypes.string).isRequired,
  price: PropTypes.number,
  hideTag: PropTypes.bool,
  shopifyProductId: PropTypes.string,
  shopifyHandle: PropTypes.string,
};

export const Children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);
