import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Field } from "react-final-form";
import { Checkbox } from "final-form-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import classes from "../../index.module.scss";

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: "'Mazzard', sans-serif",
      fontSize: 14,
      color: "##171729",
    },
  },
});

function SubscribeCheckbox() {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ paddingTop: "5px" }}
        className={`${classes.form_group} ${classes.subscribe}`}
      >
        <FormControlLabel
          control={
            <Field
              type="checkbox"
              component={Checkbox}
              name="accepts_marketing"
              color="default"
            />
          }
          label={
            <>
              Sign up for latest contest experiences, winner announcements,
              bonus entries, and Fandiem news via email. By entering your email
              address and creating an account, you acknowledge our{" "}
              <a target="_blank" href="https://fandiem.com/pages/terms-of-use">
                Terms of Use {" "}
              </a>
              and{" "}
              <a
                target="_blank"
                href="https://fandiem.com/pages/privacy-policy"
              >
                Privacy Policy
              </a>
              .
            </>
          }
        />
        {/* <p className={classes.subtext}>
          I want to receive new contest announcements,
          marketing promotions, and updates via email.
        </p> */}
      </div>
    </ThemeProvider>
  );
}

export default SubscribeCheckbox;
