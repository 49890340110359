export default [
  {
    question: 'Is this donation tax deductible?',
    answerHtml: '<p>Aoki Foundation is a 501(c)3 tax-exempt organization and your donation is tax-deductible within the guidelines of U.S. law. To claim a donation as a deduction on your U.S. taxes, please keep your email donation order confirmation receipt as your official record.</p>',
  },
  {
    question: 'Does this donation enter me into Fandiem campaigns?',
    answerHtml: '<p>No. This is a direct donation to the foundation and does not give you entries into the foundation’s active Fandiem campaigns. To receive entries, you will need to enter directly through Fandiem campaign prize page.</p>',
  },
];
