import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// import { useSpring, animated } from 'react-spring';
import { Box } from '@mui/material';
import classes from './index.module.scss';
import Countdown from '../Chips/Countdown';
import Badge from '../Chips/Badge';
import Button from '../Button';
import Media from '../../../components/Media';
import Label2 from '../Typography/Label2';
import H5 from '../Typography/H5';
import { Product } from '../../PropTypes';

function Card({
  title = '',
  media,
  // sizes = {},
  deadline = '',
  deadline_time = '',
  start,
  variants = [],
  charities,
  type,
  url,
  hideTag,
  darkMode,
}) {
  // const [toggle, setToggle] = useState(false);

  // const { imageTransform, overlayOpacity } = useSpring({
  //   imageTransform: toggle
  //     ? 'translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg) rotateY(0deg)
  // rotateZ(0deg) skew(0deg, 0deg)'
  //     : 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
  // rotateZ(0deg) skew(0deg, 0deg)',
  //   overlayOpacity: toggle ? 1 : 0,
  // });
  let charityPrefix;
  let buttonText;
  let badgeText = 'sweeps';

  switch (type) {
    case 'NFT':
      charityPrefix = 'supporting';
      buttonText = 'claim now';
      badgeText = '#nft';
      break;
    case 'AUCTION':
      charityPrefix = 'supporting';
      buttonText = 'place a bid';
      badgeText = '#auction';
      break;
    case 'SWEEPSTAKES':
      charityPrefix = 'donate to';
      buttonText = 'enter to win';
      badgeText = '#sweeps';
      break;
    case 'FANDM':
      charityPrefix = 'supporting';
      buttonText = 'donate now';
      badgeText = '#fandm';
      break;
    case 'HEADLINER':
      charityPrefix = 'supporting';
      buttonText = 'go';
      badgeText = '#headliner';
      break;
    default:
  }

  if (url === '/sweeps/win-a-trip-to-meet-depeche-mode' || url === '/sweeps/win-a-front-of-stage-experience-with-red-hot-chili-peppers-a-signed-guitar' || url === '/sweeps/win-a-weekend-at-power-trip-23') {
    charityPrefix = 'Donate and Support';
  }

  let isOver = false;
  // const oneDayLeft = false;
  let twoDaysLeft = false;
  let date;
  const now = dayjs();
  if (deadline && deadline_time && deadline !== 'Invalid Date' && deadline_time !== 'Invalid Date') {
    date = dayjs(`${deadline} ${deadline_time} +0000`);
    isOver = date.isBefore(now);
    twoDaysLeft = !isOver && date.isBefore(dayjs().add(2, 'days'));
    // const oneDayFromNow = now.add(1, 'days');
    // oneDayLeft = date.isBefore(oneDayFromNow);
  }
  if (isOver) {
    buttonText = `${type === 'SWEEPSTAKES' ? 'SWEEPS ENDED' : `${type.toUpperCase()} ENDED`}`;
    charityPrefix = 'SUPPORTED';
  } else if ((start && now.isBefore(dayjs(start))) || ((type === 'NFT' || type === 'FANDM') && !variants.length)) {
    buttonText = 'COMING SOON';
  }

  let charityText = '';
  if (charities && charities.length > 1) {
    charityText = (
      <>
        <span className="gradient_pink_blue_text">
          {`${charities.map((name) => name).slice(0, -1).join(', ')}`}
        </span>
        <span> & </span>
        <span className="gradient_pink_blue_text">
          {`${charities.slice(-1)[0]}`}
        </span>
      </>
    );
  } else if (charities && charities.length) {
    const [theText] = charities;

    charityText = (
      <span className="gradient_pink_blue_text">
        {theText}
      </span>
    );
  }
  return (
    <a href={url} style={{ textDecoration: 'none', color: 'inherit' }}>
      <Box
        className={`${darkMode ? classes.fandiem_card_dark_mode : ''} ${classes.fandiem_card}`}
        sx={{
          display: 'grid',
          borderRadius: '40px',
          p: {
            xs: 0,
            sm: 2,
          },
        }}
        gap={{
          xs: 1,
          sm: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: '24px', overflow: 'hidden', paddingTop: '100%', zIndex: 0,
          }}
          className={`w-100 position-relative ${classes.top}`}
        >
          <div className={`aspect-ratio-box-inside ${classes.fandiem_card__image}`}>
            <Media
              preload="metadata"
              sizes={{
                xs: '30vw', sm: '20vw', md: '15vw', xl: '35vw',
              }}
              style={{ objectFit: 'cover', zIndex: 1 }}
              media={media && (media.contentType === 'video/mp4' || media.contentType === 'video/quicktime')
                ? {
                  src: `${media.src}`,
                  contentType: media.contentType,
                } : (media || {})}
              className="w-100 h-100"
              alt={title}
            />
          </div>
          <Box sx={{ filter: 'drop-shadow(23px 25px 99px rgba(0, 0, 0, 0.25))', background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 67.33%, rgba(0, 0, 0, 0.9) 100%)', zIndex: 2 }} className={`${classes.fandiem_card__gradient} aspect-ratio-box-inside`} />
          <div style={{ zIndex: 3 }} className={`aspect-ratio-box-inside ${classes.fandiem_card__buttons} ${isOver ? classes.is_over : ''}`}>
            <Box sx={{ width: '100%', height: '60%' }} gridTemplateColumns="1fr" gridTemplateRows="auto auto" display="grid" justifyContent="space-between" alignContent="space-between">
              <Box gridColumn="1/2" gridRow="1/2" display="grid" gridTemplateColumns="auto auto" alignContent="space-between" justifyContent="space-between">
                {date && twoDaysLeft && (
                  <Box gridColumn="1/2" gridRow="1/2" alignSelf="center" justifySelf="start">
                    <Countdown date={date} />
                  </Box>
                )}
              </Box>
              <Box className={classes.button_wrapper_grid}>
                <Box className={classes.button_wrapper}>
                  <Button variant={isOver ? 'red' : 'gray'} className="text-uppercase">
                    {buttonText}
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        </Box>
        <Box
          justifySelf="start"
          alignSelf="end"
          display="grid"
          gridTemplateRows="auto auto"
          sx={{
            padding: {
              xs: '0px 14px 14px 14px',
              sm: 'unset',
            },

          }}
          gap={{
            xs: 1,
            sm: 2,
          }}
        >
          {charityText ? (
            <Box gridRow="span 1">
              <Label2 className={classes.fandiem_card__charity}>
                <span className={classes.fandiem_card__donate_to}>
                  {charityPrefix.toUpperCase()}
                  {' '}
                </span>
                <span className="text-uppercase">
                  {charityText}
                </span>
              </Label2>
            </Box>
          ) : null}
          <Box gridRow="span 1">
            <H5 className={classes.fandiem_card__title}>
              {title}
            </H5>
          </Box>
          {
            !hideTag
              ? (
                <Box gridRow="span 1">
                  <Badge className="text-uppercase" variant={darkMode ? 'white' : 'black'} border>{badgeText}</Badge>
                </Box>
              ) : null
          }
        </Box>
      </Box>
    </a>

  );
}

Card.defaultProps = {
  sizes: {
    xs: '100vw',
  },
  deadline: undefined,
  deadline_time: undefined,
  variants: [],
  hideTag: false,
  darkMode: false,
};

Card.propTypes = {
  ...Product,
  hideTag: PropTypes.bool,
  sizes: PropTypes.shape({
    xs: PropTypes.string,
  }),
  deadline: PropTypes.string,
  deadline_time: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  darkMode: PropTypes.bool,
};

export default Card;
