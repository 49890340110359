import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { range } from 'lodash';
import { Product } from '../../PropTypes';
import Card from '../Card';
import FallbackCard from '../FallbackCard';

function CardGrid({ experiences, loading, darkMode }) {
  return (
    <Box
      display="grid"
      gridRow="span 1"
      gridTemplateColumns={{ xs: '1fr 1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' }}
      columnGap={{
        xs: 1,
        sm: 4,
      }}
      rowGap={{
        xs: 3,
        sm: 8,
      }}
    >
      {experiences && !loading ? experiences.map((drop) => (
        <Box key={drop.title} gridColumn="span 1" gridRow="span 1">
          <Card {...drop} darkMode={darkMode} />
        </Box>
      )) : range(20).map((num, index) => (
        <Box key={index} gridColumn="span 1" gridRow="span 1">
          <FallbackCard />
        </Box>
      ))}
    </Box>
  );
}

CardGrid.defaultProps = {
  loading: false,
  darkMode: false,
};

CardGrid.propTypes = {
  loading: PropTypes.bool,
  experiences: PropTypes.arrayOf(PropTypes.shape(Product)).isRequired,
  darkMode: PropTypes.bool,
};

export default CardGrid;
