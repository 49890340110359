import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import elementReady from '../../../util/elementReady';
import Button from '../Button';

import './social.scss';

function ShareButton({ className, parentSelector, children }) {
  const [shareLoaded, setShareLoaded] = useState(false);

  const nativeShare = !!window.navigator.share;

  useEffect(() => {
    (async () => {
      if (!nativeShare) {
        await elementReady('.eapps-social-share-buttons-item-more');
        window.$(parentSelector).prepend(window.$('.eapps-social-share-buttons-item-more'));
        window.$('.eapps-social-share-buttons-item-more').empty();
        window.$('.eapps-social-share-buttons-item-more').attr('class', className);
        window.$('#social-share-container').css('visibility', 'hidden');
        window.$('#social-share-container').css('height', '0');
        window.$('#social-share-container').css('overflow', 'hidden');
        setShareLoaded(true);
      } else {
        window.$('#social-share-container').css('height', '0');
        window.$('#social-share-container').css('overflow', 'hidden');
        window.$('#social-share-container').css('display', 'none');
      }
    })();
    // if (res && window.$) {
    //   window.$('#action_buttons').prepend(window.$('#social-share-container'));
    // }
  }, []);
  const classSelector = className.split(' ').map((name) => `.${name}`).join('');

  const onClick = () => {
    navigator.share({
      title: document.title,
      url: window.location.href,
    });
  };

  if (nativeShare) {
    return (
      <Button type="button" onClick={onClick} className={className}>
        {children}
      </Button>
    );
  }
  return shareLoaded && window.$(classSelector).get(0) &&  (
  <Portal domNode={window.$(classSelector).get(0)}>
    {children}
  </Portal>
  );
}

ShareButton.propTypes = {
  className: PropTypes.string.isRequired,
  parentSelector: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default ShareButton;
