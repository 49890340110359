import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Select } from '@mui/material';
import classes from './index.module.scss';
import { Children } from '../../PropTypes';

const Button = forwardRef(({
  variant, className, children, link, href, select, textField, fixOffset, ...props
}, ref) => {
  let Component = 'button';
  if (link) {
    Component = 'a';
  } else if (select) {
    Component = Select;
  } else if (textField) {
    Component = 'input';
  }
  return (
    <Component
      ref={ref}
      href={href}
      role="button"
      className={`${classes[variant]} ${classes.button} ${fixOffset ? classes.fix_offset : ''} ${className ?? ''}`}
      {...props}
    >
      <Box as="span">
        {children}
      </Box>
    </Component>
  );
});

Button.defaultProps = {
  link: false,
  href: '',
  className: undefined,
  variant: 'white',
  fixOffset: false,
  select: false,
  textField: false,
};

Button.propTypes = {
  link: PropTypes.bool,
  select: PropTypes.bool,
  textField: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'white',
    'black',
    'gray',
    'pink_blue',
  ]),
  fixOffset: PropTypes.bool,
  children: Children.isRequired,
};

export default Button;
