// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VHd6ZBkxvy3Vl9WpXiJw{border-bottom:1px solid rgba(95,42,247,0.08)}.VHd6ZBkxvy3Vl9WpXiJw .k1Jc47QC0GLvQY4OnQgM{font-size:16px !important}.VHd6ZBkxvy3Vl9WpXiJw p{font-size:16px !important}.VHd6ZBkxvy3Vl9WpXiJw p:not(:first-of-type){margin-top:16px}\n", "",{"version":3,"sources":["webpack://./src/V2/pages/Donation/components/FAQ/components/Accordion.js/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAgD,CADlD,4CAGI,yBAA0B,CAH9B,wBAMI,yBAA0B,CAN9B,4CAQM,eAAgB","sourcesContent":[".accordion_wrapper {\n  border-bottom: 1px solid rgba(95, 42, 247, 0.08);\n  .content_inner {\n    font-size: 16px !important;\n  }\n  p {\n    font-size: 16px !important;\n    &:not(:first-of-type) {\n      margin-top: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion_wrapper": "VHd6ZBkxvy3Vl9WpXiJw",
	"content_inner": "k1Jc47QC0GLvQY4OnQgM"
};
export default ___CSS_LOADER_EXPORT___;
