import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classes from './index.module.scss';
import CaretIcon from '../Icons/CaretLarge';
import { Children } from '../../PropTypes';

function BootstrapAccordion({
  title, caretIconVariant, children, defaultOpen, titleStyle = {},
}) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Accordion as="div" className={classes.accordion} defaultActiveKey={defaultOpen ? '0' : null}>
      <Accordion.Toggle
        onClick={() => setOpen(!open)}
        eventKey="0"
        className={`${classes.toggle} ${open ? classes.active : ''}`}
      >
        <span style={titleStyle}>{title}</span>
        <span>
          <CaretIcon variant={caretIconVariant} />
        </span>
      </Accordion.Toggle>
      <Accordion.Collapse style={{ overflow: 'hidden' }} eventKey="0" className={classes.content}>
        {children}
      </Accordion.Collapse>
    </Accordion>
  );
}

BootstrapAccordion.defaultProps = {
  caretIconVariant: 'dark',
  defaultOpen: false,
  titleStyle: {},
};

BootstrapAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: Children.isRequired,
  caretIconVariant: PropTypes.string,
  defaultOpen: PropTypes.bool,
  titleStyle: PropTypes.shape({}),
};

export default BootstrapAccordion;
