// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j2QJcDF7SDBrvI4kNu3f{font-family:'Mazzard', sans-serif !important}.pWwJ5GImS2FaGPPTwrKn{background:rgba(243,243,243,0.4);border-radius:16px !important}\n", "",{"version":3,"sources":["webpack://./src/V2/components/MuiTextFieldAlt/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAA6C,CAC9C,sBAGC,gCAAoC,CACpC,6BAA8B","sourcesContent":[".helper_text {\n  font-family: 'Mazzard', sans-serif !important;\n}\n\n.text_field {\n  background: rgba(243, 243, 243, 0.4);\n  border-radius: 16px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helper_text": "j2QJcDF7SDBrvI4kNu3f",
	"text_field": "pWwJ5GImS2FaGPPTwrKn"
};
export default ___CSS_LOADER_EXPORT___;
