import React from 'react';
import useFetch from '../../../util/useFetch';
import Layout from '../../layouts/Main';
import Content from './components/Content';
import Fallback from '../../components/DetailFallback';
import api from '../../../util/api';

function Donation() {
  const path = window.location.pathname;
  const id = path.split('/').pop();

  const { loading, res } = useFetch(`${api}/public/donations/${id}`);

  return (
    <Layout>
      {!loading && res && res.data ? (
        <Content
          data={res.data}
        />
      ) : <Fallback />}

    </Layout>
  );
}

export default Donation;
