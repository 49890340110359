import React from 'react';
import getSrcSet from './getSrcSet';
import getSmallSrc from './getSmallSrc';

const S3StaticDomain = 'https://d1tkzf9ss7ctgm.cloudfront.net';
const S3StorageDomain = 'https://fandiem-storage.s3.us-east-2.amazonaws.com';

function getCDNLink(src) {
  try {
    let fullSrc = src;
    if (src.startsWith('//')) {
      fullSrc = `https:${src}`;
    } else if (src.startsWith('static')) {
      fullSrc = `http://localhost:3000/${src}`;
    } else if (src.startsWith('/')) {
      fullSrc = `http://localhost:3000${src}`;
    }
    const url = new URL(fullSrc);
    if (url.origin === S3StorageDomain) {
      return src.replace(S3StorageDomain, S3StaticDomain);
    }
    return src;
  } catch (e) {
    return src;
  }
}

const useProgressiveImg = (originalSrc, breakpoints) => {
  const lowQualitySrc = getSmallSrc(originalSrc);
  const [image, setImage] = React.useState({ src: lowQualitySrc, loaded: false });

  React.useEffect(() => {
    const img = new Image();
    img.src = getCDNLink(originalSrc);
    const { sizes, srcSet } = getSrcSet(originalSrc, breakpoints);
    img.srcset = srcSet;
    img.sizes = sizes;

    img.onload = () => {
      setImage({ src: img.currentSrc, loaded: true });
    };
    return () => { img.onload = undefined; };
  }, [originalSrc]);

  if (!originalSrc) return ['', { blur: false }];

  return [image.src, { blur: !image.loaded, ...image }];
};

export default useProgressiveImg;
