import React from 'react';

function HeartIcon() {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6323 2.07357C13.2918 1.73291 12.8876 1.46267 12.4426 1.2783C11.9976 1.09392 11.5207 0.999023 11.039 0.999023C10.5574 0.999023 10.0804 1.09392 9.63544 1.2783C9.19047 1.46267 8.78618 1.73291 8.44568 2.07357L7.73901 2.78024L7.03234 2.07357C6.34455 1.38578 5.4117 0.99938 4.43901 0.99938C3.46632 0.99938 2.53347 1.38578 1.84568 2.07357C1.15788 2.76137 0.771484 3.69422 0.771484 4.66691C0.771484 5.6396 1.15788 6.57245 1.84568 7.26024L2.55234 7.96691L7.73901 13.1536L12.9257 7.96691L13.6323 7.26024C13.973 6.91974 14.2432 6.51545 14.4276 6.07048C14.612 5.6255 14.7069 5.14857 14.7069 4.66691C14.7069 4.18525 14.612 3.70831 14.4276 3.26334C14.2432 2.81836 13.973 2.41408 13.6323 2.07357V2.07357Z" stroke="#171729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

HeartIcon.defaultProps = {};

HeartIcon.propTypes = {};

export default HeartIcon;
