import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useFetch(url, { options = {}, onSuccess, wait = false } = {}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [res, setRes] = useState(null);

  useEffect(() => {
    if (wait) { return; }

    // CREAT NEW CANCEL TOKEN
    const cancelTokenSource = axios.CancelToken.source();

    // FETCH DATA
    (async () => {
      try {
        const innerRes = await axios.get(url, { ...options, cancelToken: cancelTokenSource.token });
        setLoading(false);
        setRes(innerRes);
        if (onSuccess) onSuccess(innerRes);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    })();

    // CANCEL PREVIOUS REQUEST
    return () => cancelTokenSource.cancel(); // eslint-disable-line
  }, [url, wait]);

  return { loading, error, res };
}
