import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import BeatLoader from 'react-spinners/BeatLoader';
import classes from './index.module.scss';
import TextField from '../../../../components/MuiTextField';
import Button from '../../../../components/Button';

function LoginForm({ onSubmit, validate }) {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <input type="hidden" name="form_type" value="customer_login" />
          <input type="hidden" name="utf8" value="✓" />
          <div className={classes.form_group}>
            <Field
              component={TextField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder="john@doe.com"
              label="Email Address"
            />
          </div>
          <div className={classes.form_group}>
            <Field
              component={TextField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              placeholder="password"
              label="Password"
            />
          </div>

          <Grid
            justify="center"
            alignItems="center"
            item
            xs={12}
          >
            {submitError && <div style={{ color: '#d32f2f' }}>{submitError}</div>}
          </Grid>

          <Button
            disabled={submitting}
            type="submit"
            variant="primary"
            className={`${classes.submit}`}
            fixOffset
          >
            {submitting ? (
              <BeatLoader
                css={{
                  top: '0.3em',
                  position: 'relative',
                }}
                size={15}
                color="#fff"
              />
            ) : 'Sign In'}
          </Button>
        </form>
      )}
    />
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

export default LoginForm;
