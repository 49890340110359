/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import { Modal } from 'react-bootstrap';
import usePost from 'use-http';
import { debounce } from 'lodash';
import classes from './index.module.scss';
import Media from '../../../../../components/Media';
import AreaImage from '../../../../../components/AreaImage';
import Button from '../../../../components/Button';
import H2 from '../../../../components/Typography/H2';
import H4 from '../../../../components/Typography/H4';
import useKlaviyo from '../../../../../util/useKlaviyo';
import CardGrid from '../../../../components/CardGrid';
import FAQ from '../FAQ';
import HeartIcon from '../../../../components/Icons/Heart';
import ShareButton from '../../../../components/ShareButton';
import ShareIcon from '../../../../components/Icons/Share';
import buttonClasses from '../../../../components/Button/index.module.scss';
import AuthModal from '../../../SweepDetail/components/AuthModal';
import api from '../../../../../util/api';
import { useCustomer } from '../../../../../util/LoggedInProvider';
import Responsive from '../../../../../components/Responsive';

function stepwisePercent(length, index, min) {
  const diff = 100 - min;
  if (index === 0) return min;
  if (index === length - 1) return 100;
  const percent = ((index + 1) / length);
  const plusAmount = percent * diff;
  return min + plusAmount;
}

function Content({ data }) {
  const {
    charity: {
      name,
      bio,
      image,
      coverImage,
      sweeps,
      entryOptions,
      shopifyProductId,
      apiHandle,
    },

  } = data;

  const url = 'nonprofits/headcount';
  const {
    post: like,
    // response: likeRes,
    loading: likeLoading,
    // error: likeError,
  } = usePost(api, { credentials: 'include' });

  const [liked, setLiked] = useState(false);
  const { loggedIn } = useCustomer();

  const [showAuthModal, setShowAuthModal] = useState(false);
  const onLikeClick = debounce(async () => {
    if (loggedIn && !likeLoading) {
      if (liked) {
        await like('/accounts/unlike', { promotionId: data?.id });
        setLiked(false);
      } else {
        await like('/accounts/like', { promotionId: data?.id });
        setLiked(true);
      }
    } else {
      setShowAuthModal(true);
      // window.location.replace('/account/login');
    }
  }, 250);

  const onConnected = async () => {
    await like('/accounts/like', { promotionId: data?.id });
    setLiked(true);
    // setShowModal(false);
  };

  const handleClick = useKlaviyo(entryOptions.map((option) => ({
    id: option.shopifyVariantId,
    variant_id: option.shopifyVariantId,
    title: `${name} - ${option.title}`,
    entryQuantity: option.entryQuantity,
    price: option.price,
    sku: `${name.split(' ').join('-')}-${option.title.split(' ').join('-')}`.toLowerCase(),
    product_id: shopifyProductId,
    image: image.url,
    url: `https://fandiem.com/products/${apiHandle}?variant=${option.shopifyVariantId}`,
  })));

  const parser = new DOMParser();
  const doc = parser.parseFromString(bio, 'text/html');
  const media = doc.querySelectorAll('iframe, video, img');

  const getMedia = Array(...media).map((frame) => frame.outerHTML).join('<br>');

  if (media.length > 0) {
    Array(...media).forEach((node) => {
      const isOnlyChild = node.parentNode.childElementCount === 1;
      if (isOnlyChild) {
        node.parentNode.remove();
      } else {
        node.remove();
      }
    });
  }

  return (
    <>
      {/* Cover image */}
      {coverImage && (
      <div className={classes.cover_image}>
        <Media media={{ src: coverImage.url, contentType: coverImage.contentType }} />
      </div>
      )}
      <Box sx={{ pt: 3 }} display="grid">
        <div className={classes.content_wrapper}>
          <Box
            sx={{
              maxWidth: {
                xs: '98%',
                lg: '1500px',
              },
            }}
            className={classes.header}
          >
            <div id="action_buttons" className={classes.action_buttons}>
              <ShareButton
                parentSelector="#action_buttons"
                className={`${buttonClasses.button} ${buttonClasses.white} ${classes.action} ${classes.action__share}`}
              >
                <ShareIcon />
              </ShareButton>
              <Modal
                show={showAuthModal}
                onHide={() => { setShowAuthModal(false); }}
              >
                <AuthModal
                  onDone={onConnected}
                  hideModal={() => { setShowAuthModal(false); }}
                />
              </Modal>
              <Button
                variant={liked ? 'primary' : 'white'}
                className={`${classes.action} ${classes.action__like}`}
                disabled={!!likeLoading}
                onClick={onLikeClick}
              >
                {likeLoading ? (
                  <ClipLoader
                    css={{
                      top: '0.1em',
                      position: 'relative',
                    }}
                    size={15}
                    color={liked ? '#fff' : '#000'}
                  />
                ) : <HeartIcon />}
              </Button>
            </div>
          </Box>
        </div>
      </Box>
      <Box
        display="grid"
        gridTemplateAreas={{
          xs: `
            "content"
            "sweeps"
            "faq"
          `,
        }}
      >
        {/* Content */}
        <Box
          className={classes.section}
          gridArea="content"
          display="grid"
          columnGap={10}
          rowGap={{
            xs: 2,
            md: 4,
          }}
          gridAutoColumns="1fr 1fr"
          alignItems="center"
          sx={{
            px: 4,
            pb: {
              xs: 2,
              md: 4,
            },
          }}
          gridTemplateAreas={{
            xs: `
              "info"
              "options"
              "media"
            `,
            md: `
              "info options"
              "media media"
            `,
          }}
        >

          <Box gridArea="info" display="grid" justifyItems="center">
            <a className={classes.charity_image} target="_blank" href={url} rel="noreferrer">
              <Responsive
                mergeDesktopAndTablet
                mobile={{
                  area: 50000,
                }}
                desktop={{
                  area: 80000,
                }}
              >
                {({
                  area,
                }) => (
                  <AreaImage
                    src={image.url}
                    alt={name}
                    style={{ alignSelf: 'flex-start', justifySelf: 'center' }}
                    area={area}
                  />
                )}
              </Responsive>

            </a>
            <Box
              as="p"
              sx={{
                fontSize: {
                  xs: '0.9rem',
                  lg: '1rem',
                },
              }}
              dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}
            />
          </Box>
          <Box gridArea="options">
            <Box className={classes.entries} sx={{ mx: 'auto' }}>
              <H2 className={classes.H2}>CHOOSE DONATION AMOUNT:</H2>
              {entryOptions.map(({
                shopifyVariantId,
                price,

              }, index) => (
                <div className={classes.entry} key={shopifyVariantId}>
                  <div className={classes.button_wrapper}>
                    <a
                      href={`/cart/add?id=${shopifyVariantId}&quantity=1`}
                      onClick={handleClick}
                    >
                      <Box
                        as={Button}
                        sx={{
                          width: {
                            xs: `${stepwisePercent(entryOptions.length, index, 60)}%`,
                            md: `${stepwisePercent(entryOptions.length, index, 45)}%`,
                          },
                        }}
                        variant="primary"
                        className={`trigger-popup ${classes.entry_Box}`}
                      >
                        DONATE $
                        {price / 100}
                      </Box>
                    </a>
                  </div>
                </div>
              ))}
            </Box>
          </Box>
          {Array(...media).length ? (
            <Box
              gridArea="media"
              sx={{
                height: {
                  xs: '300px',
                  md: '500px',
                },
                mb: 4,
              }}
              alignSelf="center"
            >
              <Box
                dangerouslySetInnerHTML={{ __html: getMedia }}
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  'iframe, video, img': {
                    position: 'relative !important',
                    width: '100% !important',
                    height: '100% !important',
                    top: 0,
                    left: 0,
                    padding: 0,
                    margin: 0,
                  },
                }}
              />
            </Box>
          ) : null}
        </Box>
        {/* Related Experiences */}
        {sweeps.length ? (
          <Box
            gridArea="sweeps"
            sx={{
              backgroundColor: '#171728',
            }}
          >
            <Box>
              <Box
                as={H4}
                sx={{
                  color: 'white',
                  justifySelf: 'center',
                  textAlign: 'center',
                  pt: 4,
                  pb: 1,
                }}
              >
                Prizes you might like for
                {' '}
                {name}
              </Box>
            </Box>
            <Box
              className={classes.section}
              sx={{
                position: 'relative',
                color: 'white !important',
                pb: 4,
              }}
            >

              <CardGrid
                experiences={sweeps.slice(0, 6).map(({
                  image: sweepImage,
                  ...sweep
                }) => ({
                  media: {
                    src: sweepImage.url,
                    contentType: sweepImage.contentType,
                  },
                  ...sweep,
                }))}
                darkMode
              />

            </Box>
          </Box>
        ) : null}

        {/* FAQ */}
        <FAQ gridArea="faq" />

      </Box>
    </>
  );
}

export default Content;
