import React from 'react';
import PropTypes from 'prop-types';

function TwitterIcon({ variant }) {
  const fillColor = variant === 'light' ? 'white' : '#171729';

  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0669 29.7588C22.1394 29.7588 28.7444 19.7544 28.7444 11.0813C28.7444 10.8 28.7381 10.5125 28.7256 10.2313C30.0105 9.30207 31.1193 8.15113 32 6.83252C30.8034 7.36492 29.5329 7.71263 28.2319 7.86377C29.6017 7.04267 30.6274 5.75275 31.1187 4.23314C29.8301 4.99687 28.4208 5.5356 26.9513 5.82627C25.9611 4.7742 24.652 4.07761 23.2262 3.84419C21.8005 3.61077 20.3376 3.85352 19.0637 4.53492C17.7897 5.21631 16.7757 6.2984 16.1785 7.61387C15.5812 8.92935 15.4339 10.4049 15.7594 11.8125C13.15 11.6816 10.5972 11.0037 8.26664 9.82292C5.93604 8.64211 3.87959 6.9847 2.23062 4.95814C1.39253 6.40311 1.13608 8.11298 1.51337 9.74024C1.89067 11.3675 2.87342 12.7901 4.26188 13.7188C3.2195 13.6857 2.19997 13.405 1.2875 12.9V12.9813C1.28657 14.4977 1.8108 15.9676 2.77108 17.1411C3.73136 18.3147 5.06843 19.1195 6.555 19.4188C5.58941 19.683 4.57598 19.7215 3.59313 19.5313C4.01261 20.8354 4.82876 21.976 5.92769 22.7939C7.02662 23.6118 8.35349 24.0662 9.72313 24.0938C7.3979 25.9203 4.52557 26.911 1.56875 26.9063C1.04438 26.9055 0.520532 26.8733 0 26.81C3.00381 28.7371 6.49804 29.7606 10.0669 29.7588Z" fill={fillColor} />
    </svg>
  );
}

TwitterIcon.defaultProps = {
  variant: 'light',
};

TwitterIcon.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

export default TwitterIcon;
