/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import MuiButton from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
// import { Box } from '@mui/material';
import { Box } from '@mui/material';
import classes from './index.module.scss';
import logo from '../../../images/logo.png';
import cartSvg from '../../../images/svgs/cart.svg';
import menuSvg from '../../../images/svgs/menu_dark.svg';
import useFetch from '../../../util/useFetch';
import StyledMenu from './components/StyledMenu';
import StyledDrawer from './components/StyledDrawer';
import Button from '../Button';
import CaretIcon from '../Icons/CaretSmall';
import { useLoggedIn } from '../../../util/LoggedInProvider';
import api from '../../../util/api';

const endpoint = '/pages/menus/main-menu';

function linkProps({ id, url }) {
  const link = new URL(url);
  let extraProps = {};
  if (link.hostname !== 'fandiem.com') {
    extraProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }
  return {
    href: url,
    id,
    ...extraProps,
  };
}
function NavHeader({ isDesktop, variant }) {
  const logout = async () => {
    window.location.href = `${api}/accounts/logout`;
  };
  const { res } = useFetch(endpoint);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { loggedIn } = useLoggedIn();
  // const { res: loggedInRes, loading, error } = useFetch('/account');
  // console.log('error', error);

  // const loggedIn = !loggedInRes?.request?.responseURL.includes('account/login');

  let menuItems;
  let renderMenu;

  if (res && res.data) {
    const { data } = res;
    menuItems = data['main-menu'];
  }

  if (menuItems) {
    if (isDesktop) {
      /* Desktop Menu */
      renderMenu = menuItems.map((item) => {
        if (item.children.length) {
          return (
            <div className={classes.nav__menu_item} key={item.id}>
              <button className="btn-link" type="button" id={item.id} onClick={(event) => setAnchorEl(event.currentTarget)}>
                {item.title}
                <div className={classes.caret_icon}>
                  <CaretIcon />
                </div>
              </button>
              <div className={classes.nav__submenu}>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={anchorEl && anchorEl.id === item.id}
                  onClose={() => setAnchorEl(null)}
                >
                  {item.children.map((subItem) => (
                    <MenuItem key={subItem.id}>
                      <Link
                        {...linkProps(subItem)}
                      >
                        {subItem.title}
                      </Link>
                    </MenuItem>
                  ))}
                </StyledMenu>
              </div>
            </div>
          );
        }
        return (
          <div key={item.id} className={classes.nav__menu_item}>
            <a {...linkProps(item)}>{item.title}</a>
          </div>
        );
      });
    } else {
      /* Mobile Menu */
      const toggleDropdown = (event) => {
        if (anchorEl) {
          setAnchorEl(null);
        } else {
          setAnchorEl(event.currentTarget);
        }
      };

      renderMenu = menuItems.map((item) => {
        if (item.children.length) {
          return (
            <React.Fragment key={item.id}>
              <ListItem key={item.id}>
                <MuiButton id={item.id} onClick={toggleDropdown}>
                  {item.title}
                  <div className={classes.caret_icon}>
                    <CaretIcon variant="light" />
                  </div>
                </MuiButton>
              </ListItem>
              <Collapse in={anchorEl && anchorEl.id === item.id} unmountOnExit>
                <List component="div">
                  {item.children.map((subItem) => (
                    <ListItem key={subItem.id}>
                      <Link {...linkProps(subItem)}>{subItem.title}</Link>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          );
        }

        return (
          <ListItem key={item.id}>
            <MuiButton {...linkProps(item)}>{item.title}</MuiButton>
          </ListItem>
        );
      });
    }
  }

  let profileButtons = null;
  if (isDesktop) {
    if (loggedIn) {
      profileButtons = (
        <>
          <Button style={{ textTransform: 'unset' }} href="/account" link variant="white">Profile</Button>
          <Button style={{ textTransform: 'unset' }} onClick={logout} className="text-white" href={`${api}/accounts/logout`} link variant="black">Logout</Button>
        </>
      );
    } else {
      profileButtons = (
        <>
          <Button style={{ textTransform: 'unset' }} href="/account/login" link variant="white">Log In</Button>
          <Button style={{ textTransform: 'unset' }} className="text-white" href="/account/register" link variant="black">Sign Up</Button>
        </>
      );
    }
  } else if (loggedIn) {
    profileButtons = (
      <>
        <ListItem>
          <MuiButton className={classes.login} href="/account">Profile</MuiButton>
        </ListItem>
        <ListItem>
          <MuiButton onClick={logout} className={classes.sign_up} href={`${api}/accounts/logout`}>Logout</MuiButton>
        </ListItem>
      </>
    );
  } else {
    profileButtons = (
      <>
        <ListItem>
          <MuiButton className={classes.login} href="/account/login">Log In</MuiButton>
        </ListItem>
        <ListItem>
          <MuiButton className={classes.sign_up} href="/account/register">Sign Up</MuiButton>
        </ListItem>
      </>
    );
  }

  return (
    <>
      {window.location.pathname === '/club' ? (
        <div
          className="position-relative"
          style={{
            background: 'linear-gradient(90deg, rgb(85, 31, 234, 0.5) -23%, rgb(36, 208, 235, 0.5) 100%)',
            height: '50px',
            width: '100%',
            zIndex: 10,
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              height: '100%',
              fontSize: {
                xs: '10px',
                sm: '16px',
              },
            }}
            display="grid"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <span>
              Join a club and receive +1,000 bonus entries automatically credited to your account!
            </span>
          </Box>
        </div>
      ) : null}
      <header
        style={{
          willChange: 'transform', position: 'sticky', zIndex: 10, backdropFilter: 'blur(64px)', top: '0px',
        }}
      >

        <div
          className={`${classes.header} ${classes[variant]}`}
        >
          <a href="/" className={classes.logo}>
            <img src={logo} alt="logo" />
          </a>

          {isDesktop
            ? (
          /* Desktop */
              <div className={classes.desktop_menu}>
                <nav className={classes.nav}>
                  <div className={classes.links}>
                    {renderMenu}
                  </div>
                  <div className={classes.buttons}>
                    {profileButtons}
                    <Button style={{ padding: '11px 12px' }} className="" link variant="black" href="/cart">
                      <img src={cartSvg} alt="cart icon" />
                    </Button>
                  </div>
                </nav>
              </div>
            ) : (
          /* Mobile */
              <div className={classes.mobile_menu}>
                <button
                  type="button"
                  onClick={() => setOpenDrawer(!openDrawer)}
                  className={classes.hamburger_menu}
                  id="hamburger_menu_button"
                >
                  <img src={menuSvg} alt="menu icon" />
                </button>

                <StyledDrawer
                  open={openDrawer}
                  onClose={() => setOpenDrawer(!openDrawer)}
                >
                  <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: '#171729' }}
                    component="nav"
                  >
                    {renderMenu}
                    {profileButtons}
                    <ListItem>
                      <MuiButton className={classes.cart} href="/cart">
                        <img src={cartSvg} alt="cart icon" />
                      </MuiButton>
                    </ListItem>
                  </List>
                </StyledDrawer>
              </div>
            )}
        </div>
      </header>
    </>
  );
}

const mapSizesToProps = ({ width }) => ({
  isDesktop: width > 1280,
});

NavHeader.defaultProps = {
  variant: 'light',
};

NavHeader.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
};

export default withSizes(mapSizesToProps)(NavHeader);
