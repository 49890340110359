import PropTypes from 'prop-types';
import React from 'react';
import BootstrapAccordion from '../../../../../../components/BootstrapAccordion';
import classes from './index.module.scss';

function Accordion({ question, answerHtml }) {
  return (
    <div className={classes.accordion_wrapper}>
      <BootstrapAccordion titleStyle={{ fontSize: '18px' }} title={question} caretIconVariant="purple">
        <div className={classes.content_inner}>
          <div dangerouslySetInnerHTML={{ __html: answerHtml }} />
        </div>
      </BootstrapAccordion>
    </div>
  );
}

Accordion.propTypes = {
  question: PropTypes.string.isRequired,
  answerHtml: PropTypes.string.isRequired,
};

export default Accordion;
