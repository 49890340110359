/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isValidPhoneNumber } from 'libphonenumber-js';
import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, IconButton } from '@mui/material';
import * as Sentry from '@sentry/react';
import LoginForm from '../../../Login/components/LoginForm';
import SignupForm from '../../../SignUp/components/SignupForm';
import ForgotPassword from '../../../Login/components/ForgotPassword';
import classes from './index.module.scss';
import H2 from '../../../../components/Typography/H2';
import api from '../../../../../util/api';
import { useLoggedIn } from '../../../../../util/LoggedInProvider';
// import { useLoggedIn } from '../../../../../util/LoggedInProvider';

export const authTypes = {
  login: 0,
  signup: 1,
  done: 3,
};

function AuthModal({
  initialAuthType,
  initialUser, onDone, hideModal,
}) {
  const [authType, setAuthType] = useState(initialAuthType);
  const [user, setUser] = useState(initialUser);
  const { setLoggedIn } = useLoggedIn();
  const recaptchaRef = useRef();
  const [activateRequired, setActivateRequired] = useState(false);

  const onActivate = async (values) => { // eslint-disable-line
    try {
      const { data: { ok } } = await axios.post(`${api}/accounts/activateAccount`, values, {
        withCredentials: true,
      });

      if (ok) {
        try {
          await axios.post(
            '/account/login',
            {
              customer: {
                email: values.email,
                password: values.password,
              },
            },
          );
        } catch (e) {
          console.log(e);
        }
        const loggedInUser = await axios.get(`${api}/accounts`, { withCredentials: true });
        setUser(loggedInUser.data.fan);
        setLoggedIn(true);
        if (loggedInUser.data.fan.iconAddress) {
          setAuthType(authTypes.done);
        } else {
          setAuthType(authTypes.bridge);
        }
      }
    } catch (e) {
      if (e.response?.status === 401) {
        return { token: e.response?.data?.error || 'Something went wrong. Please refresh the page and try again.' };
      }
      if (e.response?.status === 400 && e.response?.data?.error === 'phone taken') {
        return { token: 'Phone is already associated with another account' };
      }
      Sentry.captureException(e);
      return { token: 'Something went wrong. Please refresh the page and try again.' };
    }
  };

  const onSignup = async (values) => { // eslint-disable-line
    // const errors = validate(values);

    if (values.token) {
      return onActivate(values);
    }
    const token = recaptchaRef.current.getValue();

    try {
      const { data: { ok } = {} } = await axios.post(`${api}/accounts/signup`, {
        ...values,
        captcha_token: token,
      }, {
        withCredentials: true,
      });
      if (ok) {
        try {
          await axios.post(
            '/account/login',
            {
              customer: {
                email: values.email,
                password: values.password,
              },
            },
          );
        } catch (e) {
          console.log(e);
        }

        const loggedInUser = await axios.get(`${api}/accounts`, { withCredentials: true });

        setUser(loggedInUser.data.fan);
        setLoggedIn(true);

        setAuthType(authTypes.bridge);

        // const redirect = new URL(window.location.href);
        // const checkout = redirect.searchParams.get('checkout_url');
        // const auction = redirect.searchParams.get('auction_url');

        // if (loginRes.request.responseURL === 'https://fandiem.com/challenge') {
        //   if (auction) window.localStorage.setItem('auction', auction);
        //   if (checkout) window.localStorage.setItem('checkout', checkout);
        //   window.location.replace('https://fandiem.com/challenge');
        //   return false;
        // }
        // const { data: { customer } } = await axios.get('/pages/orders');
        // if (!customer) {
        //   return { [FORM_ERROR]:
        // 'Something went wrong. Please refresh the page and try again.' };
        // }

        // if (checkout) {
        //   window.location.href = checkout;
        // } else if (auction) {
        //   window.location.href = auction;
        // } else {
        //   window.location.href = '/account';
        // }
      }
    } catch (e) {
      if (e.response?.status === 403) {
        setActivateRequired(true);
      } else if (e.response?.status === 401) {
        return { [FORM_ERROR]: e.response?.data?.error || 'Email and password not found' };
      } else if (e.response?.status === 400 && e.response?.data?.error === 'phone taken') {
        return { [FORM_ERROR]: 'Phone is already associated with another account' };
      }
      Sentry.captureException(e);
      return { [FORM_ERROR]: 'Something went wrong. Please refresh the page and try again.' };
    }
  };

  const onLogin = async (values) => { // eslint-disable-line
    // let customer;

    try {
      const { data: { ok } } = await axios.post(`${api}/accounts/login`, values, {
        withCredentials: true,
      });

      try {
        if (ok) {
          try {
            await axios.post(
              '/account/login',
              {
                customer: {
                  email: values.email,
                  password: values.password,
                },
              },
            );
          } catch (e) {
            console.log(e);
          }
          const loggedInUser = await axios.get(`${api}/accounts`, { withCredentials: true });
          setUser(loggedInUser.data.fan);
          setLoggedIn(true);

          if (loggedInUser.data.fan.iconAddress) {
            setAuthType(authTypes.done);
          } else {
            setAuthType(authTypes.bridge);
          }
          // if (loginRes.request.responseURL === 'https://fandiem.com/challenge') {
          //   if (referrer && referrer !== 'https://fandiem.com/') window.localStorage.setItem('auction', referrer);
          //   if (checkout) window.localStorage.setItem('checkout', checkout);
          //   window.location.replace('https://fandiem.com/challenge');
          //   return false;
          // }
          // const cusRes = await axios.get('/pages/orders');
          // customer = cusRes.data.customer;

          // if (!customer) {
          //   await axios.get('https://app.fandiem.com/api/accounts/logout');
          //   return { email: 'Something went wrong' };
          // }
        }
      } catch (e) {
        return { [FORM_ERROR]: 'Email and Password not recognized' };
      }

      // if (checkout) {
      //   window.location.href = checkout;
      // } else if (referrer && referrer !== 'https://fandiem.com/') {
      //   window.location.href = referrer;
      // } else {
      //   window.location.href = '/account';
      // }
    } catch (e) {
      Sentry.captureException(e);
      return { [FORM_ERROR]: 'Email and Password not recognized' };
    }
  };

  useEffect(() => {
    if (authType === authTypes.done) {
      onDone(user);
    }
  }, [authType]);

  const validateLogin = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  const validateSignup = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    // if (!values.phone) {
    //   errors.phone = 'Required';
    // }
    if (!values.first_name) {
      errors.first_name = 'Required';
    }
    if (!values.last_name) {
      errors.last_name = 'Required';
    }

    // if (values.phone && values.phone.replace(/\(|\)| |-/gm, '').length < 10) {
    //   errors.phone = 'Phone number must include area code';
    // }
    if (values.phone && !isValidPhoneNumber(`+1${values.phone}`)) {
      errors.phone = 'Invalid Phone Number';
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.password !== !values.password_confirmation) {
      errors.password_confirmation = "Password doeesn't match";
    }
    return errors;
  };

  const renderLogin = () => (
    <>
      <H2 className={`${classes.title}`}>WELCOME BACK!</H2>

      <LoginForm onSubmit={onLogin} validate={validateLogin} />

      <ForgotPassword />

      <p className={classes.signup_link}>
        <span>
          Don&apos;t have an account?
        </span>
        {' '}
        <button
          type="button"
          className="btn btn-link"
          onClick={() => setAuthType(authTypes.signup)}
        >
          Sign Up
        </button>
      </p>
    </>
  );

  const renderSignup = () => (
    <>
      <H2 className={`${classes.title} text-uppercase `}>
        become a
        {' '}
        <br />
        <span className="gradient_pink_blue_text">fan with benefits</span>
      </H2>
      <SignupForm ref={recaptchaRef} activateRequired={activateRequired} onSubmit={onSignup} validate={validateSignup} />
      <p className={classes.login_link}>
        <span>Already have an account?</span>
        {' '}
        <button type="button" className="btn btn-link" onClick={() => setAuthType(authTypes.login)}>
          Sign in
        </button>
      </p>
    </>
  );

  let render;
  switch (authType) {
    case authTypes.login:
      render = renderLogin;
      break;
    case authTypes.signup:
      render = renderSignup;
      break;
    case authTypes.done:
      hideModal();
      render = () => null;
      break;
    default:
      render = renderLogin;
      break;
  }

  return (
    <Badge badgeContent={<IconButton sx={{ top: '20px', right: '20px' }} size="small" onClick={hideModal}><CloseIcon /></IconButton>}>
      <div className={classes.container}>
        {render()}
      </div>
    </Badge>
  );
}

AuthModal.defaultProps = {
  initialAuthType: authTypes.login,
  initialUser: {},
};

AuthModal.propTypes = {
  initialAuthType: PropTypes.number,
  initialUser: PropTypes.shape({
    email: PropTypes.string,
  }),
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default AuthModal;

// () => <> <tr>
//   Copy/paste this code: <br><br>
//   {% assign activation_code = customer.account_activation_url | split: "/" | last %}
//   <b><h1>{{ activation_code }}</h1><b><br>
//     <h3>Or:<h3>
//   <td class="button__cell"><a href="{{ customer.account_activation_url }}" class="button__text">Activate your account here</a></td>
// </tr></>
