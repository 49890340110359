import React from 'react'; // eslint-disable-line
import withSizes from 'react-sizes';

const Responsive = ({
  children,
  isMobile,
  isTablet,
  mobile = {},
  desktop = {},
  tablet = {},
  mergeDesktopAndTablet = false,
  ...props
}) => {
  let responsiveProps;
  if (mergeDesktopAndTablet) responsiveProps = isMobile ? mobile : desktop;
  else if (isMobile) responsiveProps = mobile;
  else if (isTablet) responsiveProps = tablet;
  else responsiveProps = desktop;

  return children({ ...responsiveProps, ...props });
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 576,
  isTablet: width >= 576 && width < 992,
});

export default withSizes(mapSizesToProps)(Responsive);
