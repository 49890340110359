import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const StyledDrawer = styled((props) => (
  <Drawer
    anchor="right"
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    paddingTop: theme.spacing(2),
    minWidth: 180,
    background: '#171729',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '16px 0',
      '& a, & button': {
        color: '#ffffff',
        fontSize: '14px',
      },
    },
    '& a, & button': {
      color: '#ffffff',
      fontSize: '14px',
    },
    '& .MuiButton-label': {
      fontFamily: 'Mazzard, san-serif',
      letterSpacing: '1px',
    },
    '& .MuiCollapse-container .MuiListItem-gutters': {
      paddingLeft: '32px',
    },
  },
}));

export default StyledDrawer;
