import React from 'react';

function ShareIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4043 8V13.3333C3.4043 13.687 3.54477 14.0261 3.79482 14.2761C4.04487 14.5262 4.38401 14.6667 4.73763 14.6667H12.7376C13.0913 14.6667 13.4304 14.5262 13.6804 14.2761C13.9305 14.0261 14.071 13.687 14.071 13.3333V8" stroke="#171729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.4056 3.99967L8.73893 1.33301L6.07227 3.99967" stroke="#171729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.73828 1.33301V9.99967" stroke="#171729" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

ShareIcon.defaultProps = {};

ShareIcon.propTypes = {};

export default ShareIcon;
