/* eslint-disable max-len */
import React, {
  useContext, createContext,
  useState, useEffect, useMemo,
} from 'react';
// import useFetch from './useFetch';
import useFetch from 'use-http';
import { Children } from '../V2/PropTypes';
import api from './api';

const LoggedInContext = createContext();
const CustomerContext = createContext();

export function LoggedInProvider({ children }) {
  console.log('-------------inside loggedinprovider window.isLoggedIn', window.isLoggedIn);
  const [loggedIn, setLoggedIn] = useState(window.isLoggedIn);
  const {
    loading, error, get, response,
  } = useFetch(api, { credentials: 'include' });
  // const { res: loggedInRes, loading, error } = useFetch(`${api}/accounts`, { options: { withCredentials: true } });
  const [fan, setFan] = useState(response);

  useEffect(() => {
    console.log('------------loggedinprovider response', response);
    setFan(response?.data?.fan);
  }, [response, response.data]);
  useEffect(() => {
    (async () => {
      try {
        const loggedInRes = await get('/accounts');
        console.log('------------loggedinprovider loggedInRes', loggedInRes);
        const isLoggedIn = loggedInRes?.fan;
        setLoggedIn(isLoggedIn);
      } catch (e) {
        console.log('------------loggedinprovider catch error', e);
        console.log(e);
        setLoggedIn(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!response && loggedIn) {
      (async () => {
        const resp = await get('/accounts');
        console.log('----------(!response && loggedIn)', resp);
      })();
    }
  }, [loggedIn]);

  const customerContextValue = useMemo(() => ({
    loggedIn, loading, error, setLoggedIn, response, fan, setFan, get,
  }), [loggedIn, loading, error, response, get, fan]);

  const loggedInContextValue = useMemo(() => ({
    loggedIn, setLoggedIn,
  }), [loggedIn]);

  return (
    <LoggedInContext.Provider value={loggedInContextValue}>
      <CustomerContext.Provider value={customerContextValue}>
        {children}
      </CustomerContext.Provider>
    </LoggedInContext.Provider>
  );
}

LoggedInProvider.propTypes = {
  children: Children.isRequired,
};

export const useLoggedIn = () => useContext(LoggedInContext);
export const useCustomer = () => useContext(CustomerContext);
