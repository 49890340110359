import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getSmallSrc from '../util/getSmallSrc';
import ResponsiveImage from './ResponsiveImage';

function AreaImage({ area, ...props }) {
  const [width, setWidth] = useState(null);

  // Calculate widths so that images with different aspect
  // ratios fill the width of the row but have same height.
  useEffect(() => {
    (async () => {
      const img = new Image();
      img.src = getSmallSrc(props.src);
      await new Promise((resolve) => {
        img.onload = function onLoad() {
          resolve();
        };
      });

      // (area * aspectRatio) = targetWidth * targetWidth

      const { naturalWidth } = img;
      const { naturalHeight } = img;
      const naturalAspectRatio = naturalWidth / naturalHeight;
      const _widthSquared = area * naturalAspectRatio;

      setWidth(Math.sqrt(_widthSquared));
    })();
  }, [area, props.src]);

  return (
    width ? (
      <ResponsiveImage
        {...props}
        style={{
          width: `${width}px`,
          maxWidth: '100%',
          height: 'auto',
          ...(props.style || {}),
        }}
      />
    ) : null
  );
}

AreaImage.defaultProps = {
  style: {},
};

AreaImage.propTypes = {
  area: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  src: PropTypes.string.isRequired,
};

export default AreaImage;
