import React, {
  useEffect, useMemo, useContext, useRef,
  useState,
  createContext,
} from 'react';
import PropTypes from 'prop-types';
import IntersectionObserverAdmin from 'intersection-observer-admin';
import { Children } from '../../PropTypes';

const IntersectionContext = createContext();

export function IntersectionProvider({
  children,
}) {
  const intersectionObserverAdmin = useMemo(() => new IntersectionObserverAdmin(), []);

  // Add callbacks that will be called when observer detects entering and leaving viewport

  useEffect(() => () => intersectionObserverAdmin.destroy(), []);

  // Use in cleanup lifecycle hooks of your application as a whole
  // This will remove the in memory data store holding onto all of the observers
  return (
    <IntersectionContext.Provider value={intersectionObserverAdmin}>
      {children}
    </IntersectionContext.Provider>
  );
}

IntersectionProvider.propTypes = {
  children: Children.isRequired,
};

export const useInView = ({
  onEnter, onExit, options = {}, triggerOnce = false,
} = {}) => {
  const [inView, setInView] = useState(false);
  const intersectionObserverAdmin = useContext(IntersectionContext);
  const ref = useRef();

  useEffect(() => {
    intersectionObserverAdmin.observe(ref.current, {
      rootMargin: '0px 0px 100px 0px',
      threshold: 0,
      ...options,
    });

    intersectionObserverAdmin.addEnterCallback(ref.current, (e) => {
      setInView(true);
      if (onEnter) {
        onEnter(e);
      }
    });
    if (!triggerOnce) {
      intersectionObserverAdmin.addExitCallback(ref.current, (e) => {
        setInView(false);
        if (onExit) {
          onExit(e);
        }
      });
    }

    return () => intersectionObserverAdmin.unobserve(ref.current, options);
  }, [onEnter, onExit, options]);

  return {
    inView,
    ref,
  };
};

export const InView = ({ children, ...props }) => {
  const { inView, ref } = useInView(props);

  return children({ inView, ref });
};

InView.propTypes = {
  children: PropTypes.func.isRequired,
};
