import getSizedImage from './getSizedImage';

export default function getSrcSet(src, {
  xs = '',
  sm = '',
  md = '',
  lg = '',
  xl = '',
} = {}) {
  const imgSizes = [
    1,
    5,
    10,
    25,
    50,
    100,
    200,
    300,
    400,
    600,
    700,
    800,
    1200,
    2000,
    2500,
    3000,
    4472,
  ];
  const dotIndex = src.lastIndexOf('.');
  const ext = src.substring(dotIndex);
  const fullSrc = src.startsWith('//') ? `https:${src}` : src;
  const srcSet = imgSizes
    .map((size) => {
      const newSrc = getSizedImage(fullSrc, size, ext);
      return `${newSrc} ${size}w`;
    });
  const sizes = `(min-width: 576px) ${sm || xs || '100vw'}, (min-width: 768px) ${md || sm || xs || '100vw'}, (min-width: 992px) ${lg || md || sm || xs || '100vw'}, (min-width: 1200px) ${xl || lg || md || sm || xs || '100vw'}, ${xs || '100vw'}`;

  return { sizes, srcSet };
}
